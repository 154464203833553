<div class="position-relative">
  <div class="time-to-weedding">
    <div class="tick-container">
      <h1 class="time-title-1 text-shadow">YA SOLO</h1>
      <h3 class="time-title-2">FALTAN</h3>

      <div class="tick">
        <div data-repeat="true" data-layout="horizontal fit" data-transform="preset(d, h, m, s) -> delay">
          <div class="tick-group">
            <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">
              <span data-view="flip"></span>
            </div>

            <span data-key="label" data-view="text" class="tick-label"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="tick-onended-message">
      <h1 class="time-title-1 text-shadow">¡LLEGÓ EL MOMENTO!</h1>

      <div class="fireworks-container">
        <div class="w-100 d-flex justify-content-between">
          <ng-lottie [options]="options" width="200px" height="200px" (animationCreated)="animationCreated($event)" />
          <ng-lottie [options]="options" width="200px" height="200px" (animationCreated)="animationCreated($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
