import { routeE } from '../core/models/router.model';
import { DateUtils } from './../core/typescript/date.utils';
import { ActivityClass } from './../core/clases/activity.class';
import { environment } from './../environments/environment';
import { Firestore } from '@angular/fire/firestore';
import { Ss } from './../core/services/singleton.service';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { GuestClass } from '../core/clases/guest.class';
import { itspartytimeCode, LSCurrentGuestId } from '../core/models/guest.model';
import { ActivityService } from '../core/services/activity.service';
import { fun } from '../core/services/functions.service';
import { LocalstorageService } from '../core/services/localstorage.service';
import { LSSignInTime } from '../core/models/date.model';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AudioService } from '../core/services/audio.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Digital Invitations';
  firestore: Firestore = inject(Firestore);

  localStorageServ: LocalstorageService = inject(LocalstorageService);
  activityServ: ActivityService = inject(ActivityService);
  route: ActivatedRoute = inject(ActivatedRoute);
  audioServ: AudioService = inject(AudioService);
  LocalstorageServ: LocalstorageService = inject(LocalstorageService);

  ngOnInit(): void {
    Ss.firestore = this.firestore;

    this.getData(environment.activity_id).then(() => {
      this.verifyPath();
    });
    this.verifyCurrentGuest();
  }

  verifyPath() {
    this.route.queryParams.subscribe((params) => {
      const code = params['code']; // obtendrá 'itspartytime'
      if (code && code == itspartytimeCode) {
        const guests = this.activityServ.allGuest() ?? [];

        const guestFound = guests.find((guest) => guest.guestCode.toLowerCase() == code.toLowerCase());

        if (guestFound) {
          this.activityServ.currentGuest.set(guestFound);
          this.LocalstorageServ.setItem(LSCurrentGuestId, guestFound.id);

          const fechaActual = new Date().getTime();
          this.LocalstorageServ.setItem(LSSignInTime, `${fechaActual}`);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    const actualPath = fun.getActualPath();

    if (!actualPath.includes(routeE.guestList) && !actualPath.includes(routeE.assistanceList)) this.audioServ.playAudio();
  }

  async getData(id: string) {
    return new Promise<void>(async (resolve, reject) => {
      const currentActivity = await new ActivityClass().getById(id);

      if (currentActivity) {
        currentActivity['date'] = fun.parseTimeStampToDate(currentActivity.date as any);

        this.activityServ.currentActivity.set(currentActivity.toJson());

        const allGuest = (await currentActivity.getAllGuests({ parsedToClass: true })) as GuestClass[];

        if (allGuest) this.activityServ.allGuest.set(allGuest);
        resolve();
      }
    });
  }

  async verifyCurrentGuest() {
    const currentGuestId = this.localStorageServ.getItem(LSCurrentGuestId) ?? '';
    const signInTime = this.localStorageServ.getItem(LSSignInTime) ?? null;

    const deleteSession = () => {
      this.activityServ.currentGuest.set(null);
      this.localStorageServ.removeItem(LSCurrentGuestId);
      this.localStorageServ.removeItem(LSSignInTime);
    };

    if (fun.hasValue(signInTime) && signInTime) {
      const actualTime = new Date().getTime();

      const maxTimeAvailable = DateUtils.AddHours(1, new Date(Number(signInTime))).getTime();

      if (actualTime < maxTimeAvailable) {
        const currentGuest = await new GuestClass(environment.activity_id).getByActivityIdAndId(currentGuestId);
        if (currentGuest) this.activityServ.currentGuest.set(currentGuest);
      } else {
        deleteSession();
      }
    } else {
      deleteSession();
    }
  }
}
