<section class="social-section">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center gap-2 mb-1">
      <animated-icon [icon]="animatedIcon.socialPage"></animated-icon>
      <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Redes Sociales</h1>
    </div>
    <small class="text-center d-block w-100"> ¡Haznos trending! Etiquétanos y ayuda a que el amor se vuelva viral </small>

    <div class="w-100 py-5">
      @for (socialN of socialNetworks; track $index) {
      <div [id]="socialN.photo + '-info'" class="social-container" (click)="CopyToClipboard(socialN)">
        <img [src]="'assets/img/' + socialN.photo + '.png'" [alt]="socialN.photo" class="fluid-img social-img" />
        <div class="social-body no-select">{{ socialN.name }}</div>
      </div>
      }
    </div>

    <div class="w-100 mt-5">
      <div class="d-flex flex-column align-items-center gap-2 mb-1">
        <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Nuestos Filtros</h1>
        <small class="text-center d-block w-100"> Dale estilo a tus fotos con nuestros filtros exclusivos, para utilizarlos solo dale click al de tu preferencia. </small>
      </div>

      <div class="w-100 mt-4">
        <div class="row pb-2">
          @for (filter of filters; track $index) {
          <div class="col-12 col-md-6 mt-3">
            <div class="filter-group">
              <div class="filter-title no-select">{{ filter.name }}</div>
              <div class="filter-photos">
                @for (img of filter.photos; track $index) {
                <img [src]="'assets/img/' + img.path + '.jpg'" [alt]="img.path" class="fluid-img filter-img" (click)="redirectToInstagramFilter(img.url)" />
                }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
