import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { Component, inject, OnInit } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { DeviceDetectorService } from '../../../../../core/services/device-detector.service';
import { BrowserParsedE, deviceDetectorI } from '../../../../../core/models/device-detector.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'our-history',
  standalone: true,
  imports: [AnimatedIconComponent, CommonModule],
  templateUrl: './our-history.component.html',
  styleUrl: './our-history.component.scss',
})
export class OurHistoryComponent implements OnInit {
  animatedIcon = animatedIcon;
  BrowserParsedE = BrowserParsedE;
  deviceInfo!: deviceDetectorI;
  deviceDetectorServ: DeviceDetectorService = inject(DeviceDetectorService);

  ngOnInit(): void {
    this.deviceInfo = this.deviceDetectorServ.getDeviceInfo();
  }
}
