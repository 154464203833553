<div class="widget widget-page">
  <div class="widget-header">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex gap-3">
        <h3 class="mb-0">Lista de Invitados</h3>

        @if (false) {
        <button type="button" class="btn btn-primary w-fit-content" (click)="createGuests()">
          <div class="d-flex align-items-center gap-2 flex-nowrap">
            Crear Invitados
            <i class="fa-solid fa-people-group" style="font-size: 1rem"></i>
          </div>
        </button>
        }
      </div>

      <div class="d-flex gap-3">
        <button type="button" class="btn btn-light" style="float: inline-end" (click)="navigateBack()">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </button>

        @if (sessionActive) {
        <button type="button" class="btn btn-light" style="float: inline-end" (click)="deleteSession()">
          <i class="fa-solid fa-right-from-bracket"></i>
        </button>
        }
      </div>
    </div>
  </div>
  <div class="widget-content p-2 p-md-3 p-lg-5">
    @if (sessionActive) {
    <div class="w-100 h-100 custom-card card-shadow card-rounded overflow-hidden">
      <div class="custom-card-body">
        <p-table
          #GuestListTable
          [value]="guestList"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="flex"
          [customSort]="true"
          [rows]="guestList.length"
          [paginator]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="46"
          styleClass="p-datatable-sm"
          [globalFilterFields]="['name', 'guestCode']"
          currentPageReportTemplate="{totalRecords} Invitados"
          [showCurrentPageReport]="true"
          [showJumpToPageDropdown]="false"
          [showJumpToPageInput]="false"
          [showFirstLastIcon]="false"
          [showPageLinks]="false"
          [tableStyle]="{ 'min-width': '50rem' }"
          (sortFunction)="customSort($event)"
        >
          <ng-template pTemplate="caption">
            <div class="d-flex justify-content-between align-items-end">
              <div class="d-flex column-gap-3 row-gap-2 flex-wrap">
                <div class="d-flex flex-column">
                  <small>Filtro</small>
                  <input FormInput [required]="true" placeholder="Buscar..." [(ngModel)]="filterWord" class="form-input no-validate size-lg" (input)="GuestListTable.filterGlobal(filterWord, 'contains')" />
                </div>

                <div class="d-flex flex-column">
                  <small>Recepción</small>
                  <p-selectButton [options]="selectButtonOptions" [(ngModel)]="currentFilterReception" optionLabel="label" optionValue="value" (ngModelChange)="customFilter('reception')" />
                </div>

                <div class="d-flex flex-column">
                  <small>Confirmado</small>
                  <p-selectButton [options]="selectButtonOptions" [(ngModel)]="currentFilterConfirm" optionLabel="label" optionValue="value" (ngModelChange)="customFilter('confirmed')" />
                </div>

                <div class="d-flex flex-column">
                  <small>Invitación Enviada</small>
                  <p-selectButton [options]="selectButtonOptions" [(ngModel)]="currentFilterInvitated" optionLabel="label" optionValue="value" (ngModelChange)="customFilter('invitationSend')" />
                </div>
              </div>

              <div class="d-block">
                <button type="button" class="btn btn-light" style="float: inline-end" (click)="reloadGuestList()">
                  <i class="fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 33%" pSortableColumn="name" pFrozenColumn>
                Nombre
                <p-sortIcon field="name" />
              </th>
              <th style="width: 17%">
                <div class="ps-3">Código</div>
              </th>
              <th style="width: 15%">
                <div class="w-100 d-flex justify-content-center">Recepción</div>
              </th>
              <th style="width: 15%">
                <div class="w-100 d-flex justify-content-center">Confirmado</div>
              </th>
              <th style="width: 10%">
                <div class="w-100 d-flex justify-content-center text-center">Invitación enviada</div>
              </th>
              <th style="width: 10%">
                <div class="w-100 d-flex justify-content-center">Mensaje</div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-guest>
            <tr>
              <td pFrozenColumn>
                {{ guest.name }}
              </td>
              <td>
                <div [id]="'guest-code-' + guest.id" class="ps-3 d-block w-fit-content" (click)="CopyToClipboard(guest)">
                  {{ guest.guestCode | uppercase }}
                </div>
              </td>
              <td>
                <div class="w-100 d-flex justify-content-center">
                  <span class="custom-tag py-1 px-2 text-s-12" [ngClass]="{ 'c-success': guest.goToParty, 'c-danger': !guest.goToParty }"> {{ guest.goToParty ? 'Si' : 'No' }} </span>
                </div>
              </td>

              <td>
                <div class="w-100 d-flex justify-content-center">
                  <i class="pi p-2 c-pointer" [ngClass]="{ 'text-c-green pi-check-circle': guest.confirmed, 'text-c-danger pi-times-circle': !guest.confirmed }" (click)="updateGuestProp('confirmed', guest)"></i>
                </div>
              </td>
              <td>
                <div class="w-100 d-flex justify-content-center">
                  <i class="pi p-2 c-pointer" [ngClass]="{ 'text-c-green pi-check-circle': guest.invitationSend, 'text-c-danger pi-times-circle': !guest.invitationSend }" (click)="updateGuestProp('invitationSend', guest)"></i>
                </div>
              </td>
              <td>
                <div class="w-100 d-flex justify-content-center">
                  <i class="pi pi-comments" [ngClass]="{ 'text-c-black-10 c-not-allowed': !fun.hasValue(guest.message), 'text-c-black-90 c-pointer': fun.hasValue(guest.message) }" (click)="openDialog(guest)"></i>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">No hay invitados con las especificaciones introducidas.</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    } @else {
    <div class="w-100 h-100 d-flex">
      <div class="admin-card custom-card card-shadow card-rounded overflow-hidden">
        <div class="custom-card-header">
          <p class="mb-0">Introduce la contraseña</p>
        </div>
        <div class="custom-card-body">
          <div class="d-flex flex-column">
            <span class="input-icon-right">
              <input FormInput [required]="true" [type]="showPassword ? 'text' : 'password'" id="input-admin-code" [(ngModel)]="adminCode" class="form-input" (keydown.enter)="saveSession()" autofocus />
              <i class="icon c-pointer fa-regular p-2" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" (click)="showPassword = !showPassword"></i>
            </span>
            <small class="text-c-danger" id="msgContainer"></small>
          </div>
        </div>

        <div class="custom-card-footer px-3 pb-3">
          <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="saveSession()">OK</button>
        </div>
      </div>
    </div>
    }
  </div>
</div>

<div id="dialog-mask" class="dialog-mask" (click)="hideDialog()">
  <dialog id="message-dialog" (click)="$event.stopPropagation()">
    <div class="widget">
      <div class="widget-header">
        <span class="mb-5">
          <span class="text-s-16"> De: </span>
          <span class="text-s-30 text-f-tangerine text-w-500"> {{ selectedGuest?.name | titlecase }}</span>
        </span>
      </div>
      <div class="widget-content bg-light p-3 br-10">
        <span class="d-block" [innerHTML]="selectedGuest?.message || 'No ha dejado ningún mensaje'"></span>
      </div>
      <div class="widget-footer">
        <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="hideDialog()">OK</button>
      </div>
    </div>
  </dialog>
</div>
