export enum esFlipConstants {
  YEAR_PLURAL = 'Años',
  YEAR_SINGULAR = 'Año',
  MONTH_PLURAL = 'Meses',
  MONTH_SINGULAR = 'Mes',
  WEEK_PLURAL = 'Semanas',
  WEEK_SINGULAR = 'Semana',
  DAY_PLURAL = 'Días',
  DAY_SINGULAR = 'Día',
  HOUR_PLURAL = 'Horas',
  HOUR_SINGULAR = 'Hora',
  MINUTE_PLURAL = 'Minutos',
  MINUTE_SINGULAR = 'Minuto',
  SECOND_PLURAL = 'Segundos',
  SECOND_SINGULAR = 'Segundo',
  MILLISECOND_PLURAL = 'Milisegundos',
  MILLISECOND_SINGULAR = 'Milisegundo',
}
