export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD0_NbId8VKb-vavaqGKYRhIPzSEJWNY3A',
    authDomain: 'digital-invitations-4260a.firebaseapp.com',
    projectId: 'digital-invitations-4260a',
    storageBucket: 'digital-invitations-4260a.appspot.com',
    messagingSenderId: '698772926869',
    appId: '1:698772926869:web:778b244b5e514ada0e469c',
  },
  activity_id: 'FoLCXvLJUbl70zcyyUaA',
  listPsw: 'VyM14122024',
};
