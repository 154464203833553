import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { fun } from '../services/functions.service';

@Directive({
  selector: '[FormInput]',
  standalone: true,
})
export class FormInputDirective {
  constructor(private el: ElementRef) {}

  msgContainer: any;
  isInvalid = false;
  @Input() required = false;

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    const parentDiv = this.el.nativeElement.parentNode.parentNode;
    const msgContainer = parentDiv.querySelector('#msgContainer');

    this.el.nativeElement.classList.remove('invalid');
    if (msgContainer) msgContainer.textContent = '';

    if (this.required && fun.isEmpty(value)) {
      if (msgContainer) msgContainer.textContent = 'Debe ingresar un valor';
      this.el.nativeElement.classList.add('invalid');
    }
  }
}
