import { Component } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';

@Component({
  selector: 'considerations',
  standalone: true,
  imports: [AnimatedIconComponent],
  templateUrl: './considerations.component.html',
  styleUrl: './considerations.component.scss',
})
export class ConsiderationsComponent {
  colores = Array(5).fill(0);
  animatedIcon = animatedIcon;

  redirectToGooglePhotos() {
    window.open('https://photos.app.goo.gl/R8mTjxuk8DAWgBHRA', '_blank');
  }
}
