import { Injectable } from '@angular/core';
import { LSCurrentGuestId } from '../models/guest.model';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor() {}

  getItem(key: string) {
    return localStorage.getItem(key) || undefined;
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  clearSesion() {
    [LSCurrentGuestId].forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}
