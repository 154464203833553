import { Component, inject } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { ActivityService } from '../../../../../core/services/activity.service';

@Component({
  selector: 'ubications',
  standalone: true,
  imports: [AnimatedIconComponent],
  templateUrl: './ubications.component.html',
  styleUrl: './ubications.component.scss',
})
export class UbicationsComponent {
  reasonCelebration = 'Elegimos esta iglesia para nuestra boda porque es un lugar lleno de recuerdos y significado para nosotros. Aquí fue donde nos conocimos y comenzamos a construir nuestra historia juntos. Cada rincón de este lugar refleja el inicio de nuestro amor, y queremos que sea el escenario donde celebremos nuestro compromiso eterno. Este es el lugar que ha sido testigo de nuestra conexión, y no hay otro sitio que deseemos más para compartir este momento tan especial.';
  reasonReception = 'Elegimos este lugar para nuestra celebración porque refleja la alegría y el amor que queremos compartir con nuestros seres queridos. Su ambiente acogedor nos hace sentir como en casa, y sabemos que será el escenario perfecto para crear recuerdos inolvidables. Además, su belleza y encanto nos permiten celebrar nuestra unión de una manera única, rodeados de las personas que más queremos. Estamos emocionados de brindar y bailar con todos ustedes en este espacio que nos llena de felicidad.';
  animatedIcon = animatedIcon;

  selectedPlaceMsg = '';

  activityServ: ActivityService = inject(ActivityService);

  openDialog(ubication: 'celebration' | 'reception') {
    const dialog = document.querySelector('#place-dialog');
    dialog?.setAttribute('open', '');

    const dialogMask = document.querySelector('#dialog-mask');
    dialogMask?.classList.add('show');

    this.selectedPlaceMsg = ubication == 'celebration' ? this.reasonCelebration : this.reasonReception;
  }

  hideDialog() {
    const dialog = document.querySelector('#place-dialog');
    dialog?.removeAttribute('open');

    const dialogMask = document.querySelector('#dialog-mask');
    dialogMask?.classList.remove('show');

    this.selectedPlaceMsg = '';
  }
}
