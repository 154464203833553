export interface guestI {
  id: string;
  activity_id: string;
  guestCode: string;
  name: string;
  confirmed: boolean;
  goToParty: boolean;
  showConfirm: boolean;
  invitationSend: boolean;
  present: boolean;
  isStaff: boolean;
  group?: number;
  message?: string;
}
export const LSActivityGuest = 'current-activity-guest';
export const LSCurrentGuestId = 'current-guest-id';
export const itspartytimeCode = 'itspartytime';
