import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private audio: HTMLAudioElement;
  private _isPlaying = false;
  userStopAudio: boolean = false;
  get isPlaying() {
    return this._isPlaying;
  }

  constructor() {
    this.audio = new Audio('assets/audio/te_esperaba.mp3'); // Cambia el path por tu archivo
    this.audio.loop = true;
  }

  async playAudio() {
    if (!this._isPlaying) {
      try {
        await this.audio.play();
        this._isPlaying = true; // Solo se establece en true si la reproducción es exitosa
      } catch (error) {
        console.error('Error al intentar reproducir el audio:', error);
        this._isPlaying = false;
      }
    }
  }

  pauseAudio() {
    if (this._isPlaying) {
      this.audio.pause();
      this._isPlaying = false;
      if (!this.userStopAudio) this.userStopAudio = true;
    }
  }

  toggleAudio() {
    this._isPlaying ? this.pauseAudio() : this.playAudio();
  }
}
