import { GuestClass } from './guest.class';
import { guestI } from './../models/guest.model';
import { fun } from './../services/functions.service';
import { activityI } from './../models/activity.model';
import { FirebaseRequest } from '../typescript/firebaseRequest';

export class ActivityClass extends FirebaseRequest<activityI, guestI> {
  id: string = '';
  name: string = '';
  date: Date = new Date();

  constructor(params?: activityI) {
    super();
    this.firebaseCollectionName = 'activity';

    if (fun.hasValue(params) && params) {
      this.id = fun.hasValue(params?.id) ? params.id : '';
      this.name = fun.hasValue(params?.name) ? params.name : '';
      this.date = fun.hasValue(params?.date) ? params.date : new Date();
    }
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      date: this.date,
    } as activityI;
  }

  async getById(id: string) {
    try {
      const currentActivity = await this._getOne({ id });
      return new ActivityClass(currentActivity);
    } catch (error) {
      return undefined;
    }
  }

  async getAllGuests(params?: { parsedToClass: boolean }) {
    try {
      let guestArray = (await this._getAll({ id: this.id, subEntity: 'guest' })) as guestI[];

      guestArray.map((guest: guestI) => {
        guest.activity_id = this.id;
        return guest;
      });

      if (params?.parsedToClass) {
        guestArray = guestArray.map((guest: guestI) => {
          return new GuestClass(this.id, guest);
        }) as GuestClass[];
      }

      return guestArray;
    } catch (error) {
      return undefined;
    }
  }
}
