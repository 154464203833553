import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastr: ToastrService = inject(ToastrService);
  constructor() {}

  show(type: toastType, msg: string) {
    this.toastr.clear();
    this.toastr[type](msg, '');
  }
}
export enum toastType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}
