import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[visibleObserver]',
  standalone: true,
})
export class VisibleDirective implements AfterViewInit {
  @Output() isVisible = new EventEmitter<void>();
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isVisible.emit();
            this.observer.unobserve(this.el.nativeElement); // Detiene la observación si ya se vio
          }
        });
      },
      { threshold: 1.0 },
    ); // Se dispara cuando el 100% del elemento es visible

    this.observer.observe(this.el.nativeElement);
  }
}
