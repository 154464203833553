<div class="head-line d-flex flex-column align-items-center gap-2" [ngClass]="{ ios: deviceInfo.browser_parsed === BrowserParsedE.safari }">
  <animated-icon [icon]="animatedIcon.literature"></animated-icon>
  <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Nuestra Historia</h1>
</div>

<section class="horizontal-scroll">
  <div class="custom-container" [ngClass]="{ ios: deviceInfo.browser_parsed === BrowserParsedE.safari }">
    <article>
      <div class="widget">
        <div class="widget-header">
          <h1 class="mb-0 text-f-glamour text-w-700 text-c-tertiary-dark">14/02/2019</h1>
          <div class="emoji">
            <animated-icon [icon]="animatedIcon.corazon" width="40px" height="40px"></animated-icon>
          </div>
        </div>
        <div class="widget-content">
          <div class="d-flex justify-content-center align-items-center h-100">
            <img src="assets/img/quieres_ser_novia.jpg" alt="Pedida de noviazgo" class="img-fluid history-img quieres-ser-novia" />
          </div>
        </div>
        <div class="widget-footer">
          <p class="no-select text-justify">
            Nuestra historia de amor comenzó hace seis años, en un momento inesperado que cambió nuestras vidas para siempre. Desde el primer instante, supimos que habíamos encontrado a nuestro ser especial. Con cada risa compartida y cada desafío
            superado, nuestra conexión se hizo más fuerte.
          </p>
        </div>
      </div>
    </article>

    <article>
      <div class="widget">
        <div class="widget-header">
          <h1 class="mb-0 text-f-glamour text-w-700 text-c-tertiary-dark">11/07/2020</h1>
          <div class="emoji">
            <animated-icon [icon]="animatedIcon.home" width="40px" height="40px"></animated-icon>
          </div>
        </div>
        <div class="widget-content">
          <div class="d-flex justify-content-center align-items-center h-100">
            <img src="assets/img/mudados.jpg" alt="Foto de cuando nos mudamos juntos" class="img-fluid history-img mudados" />
          </div>
        </div>
        <div class="widget-footer">
          <p class="no-select text-justify">
            A lo largo de estos años, hemos vivido momentos inolvidables, como por ejemplo, esta fue la primera foto en nuestro primer apartamento luego de decidir mudarnos juntos. Todos se sorprendieron y cuestionaron nuestra decisión por lo pronto
            que fue, pero nuestros corazones siempre latieron en conjunto haciéndonos saber que éramos el uno para el otro.
          </p>
        </div>
      </div>
    </article>

    <article>
      <div class="widget">
        <div class="widget-header">
          <div class="w-100 d-flex justify-content-around gap-2">
            <h1 class="mb-0 text-f-glamour text-w-700 text-c-tertiary-dark">23/10/2021</h1>

            <h1 class="mb-0 text-f-glamour text-w-700 text-c-tertiary-dark">18/03/2023</h1>
          </div>

          <div class="emoji">
            <animated-icon [icon]="animatedIcon.birrete" width="40px" height="40px"></animated-icon>
          </div>
        </div>
        <div class="widget-content">
          <div class="d-flex justify-content-around gap-2 align-items-center h-100">
            <img src="assets/img/grad_victor.jpg" alt="Graduación Victor" class="img-fluid history-img grad-victor" />

            <img src="assets/img/grad_maria.jpg" alt="Graduación María" class="img-fluid history-img grad-maria" />
          </div>
        </div>
        <div class="widget-footer">
          <p class="no-select text-justify">
            No solo hemos crecido como pareja, sino también como individuos. Nos hemos animado a alcanzar nuestras metas, apoyándonos en cada paso del camino. Aquí la muestra de nuestras graduaciones como profesionales siempre confiando el uno al
            otro.
          </p>
        </div>
      </div>
    </article>

    <article>
      <div class="widget">
        <div class="widget-header">
          <h1 class="mb-0 text-f-glamour text-w-700 text-c-tertiary-dark">12/05/2023</h1>
          <div class="emoji">
            <animated-icon [icon]="animatedIcon.anilloGlow" width="40px" height="40px"></animated-icon>
          </div>
        </div>
        <div class="widget-content">
          <div class="d-flex justify-content-center align-items-center h-100">
            <img src="assets/img/casate_conmigo.jpg" alt="Foto de cuando nos comprometimos" class="img-fluid history-img comprometidos" />
          </div>
        </div>
        <div class="widget-footer">
          <p class="no-select text-justify">
            ¡Uno de los mejores días de nuestra vida! Donde nuestros sueños ya se empezaron a convertir en planes concretos. Cada día desde entonces ha sido una celebración de nuestro amor y el compromiso que asumimos el uno con el otro. Esperando
            con ansias el día que daremos el siguiente paso y sellaremos nuestro amor con un "sí, quiero" ante Dios y nuestros seres queridos.
          </p>
        </div>
      </div>
    </article>
  </div>
</section>
