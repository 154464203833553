<div id="dialog-mask" class="dialog-mask" (click)="hideDialog()">
  <dialog id="code-dialog" (click)="$event.stopPropagation()">
    <div class="d-block">
      <div class="d-block">
        <p>Introduce tu código de invitado</p>

        <div class="d-flex flex-column">
          <input FormInput [required]="true" id="input-guest-code" [(ngModel)]="guestCode" class="form-input text-center text-uppercase" (keydown.enter)="closeCodeDialog()" autofocus />
          <small class="text-c-danger" id="msgContainer"></small>
        </div>
      </div>

      <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="closeCodeDialog()">OK</button>
    </div>
  </dialog>
</div>

<section class="welcome-section">
  <img src="assets/img/flor1.png " alt="flor" class="img-fluid flower-bg up" />

  <img src="assets/img/welcome-graph.svg" class="branch up" />

  <div class="w-100 d-flex flex-column align-items-center fade-in" style="z-index: 80; --fade_in_duration: 5s">
    <h1>María & Victor</h1>

    <span> Te invitan a celebrar con ellos este gran día </span>
    <div class="text-center d-flex flex-column row-gap-1">
      <img src="assets/img/sello.png" class="sello c-pointer animate__animated animate__pulse animate__infinite" (click)="openCodeDialog()" />
      <i class="fa-solid fa-angles-up text-c-black-20"></i>
      <span class="text-w-300"> Presione aquii </span>
    </div>
  </div>

  <img src="assets/img/flor1.png" alt="flor" class="img-fluid flower-bg down" />
  <img src="assets/img/welcome-graph.svg" class="branch down" />
</section>
