import { InvitationBodyComponent } from '../invitation-body/invitation-body.component';
import { WelcomeComponent } from '../welcome/welcome.component';
import { LocalstorageService } from './../../../core/services/localstorage.service';
import { LSCurrentGuestId } from './../../../core/models/guest.model';
import { AudioService } from './../../../core/services/audio.service';
import { RouterOutlet } from '@angular/router';
import { JsonPipe } from '@angular/common';
import { AnimatedIconComponent } from './../../components/animated-icon/animated-icon.component';
import { AfterViewInit, Component, inject } from '@angular/core';
import { animatedIcon } from '../../components/animated-icon/animated-icon.model';
import { ActivityService } from '../../../core/services/activity.service';
import { fun } from '../../../core/services/functions.service';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterOutlet, WelcomeComponent, InvitationBodyComponent, AnimatedIconComponent, JsonPipe],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements AfterViewInit {
  activityServ: ActivityService = inject(ActivityService);
  audioServ: AudioService = inject(AudioService);
  localStorageServ: LocalstorageService = inject(LocalstorageService);

  get currentGuestId() {
    return this.localStorageServ.getItem(LSCurrentGuestId) ?? '';
  }

  animatedIcon = animatedIcon;
  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.audioServ.isPlaying && !this.audioServ.userStopAudio) {
        const audioBtn = document.getElementById('auto-toggle-btn');
        if (audioBtn) audioBtn.click();
      }
    }, 1000);
  }

  toggleAudio() {
    this.audioServ.toggleAudio();
  }
}
