import { CopyToClipboardService } from '../../../../../core/services/copy-to-clipboard.service';
import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { Component, inject } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { CommonModule } from '@angular/common';
import { infoBancaria } from './gift-section.model';
import { ToastService, toastType } from '../../../../../core/services/toast.service';
import { fun } from '../../../../../core/services/functions.service';

@Component({
  selector: 'gift-section',
  standalone: true,
  imports: [AnimatedIconComponent, CommonModule],
  templateUrl: './gift-section.component.html',
  styleUrl: './gift-section.component.scss',
})
export class GiftSectionComponent {
  animatedIcon = animatedIcon;

  datosBancarios: infoBancaria[] = [
    { id: 'victor', nombre: 'Victor J. Vásquez', cedula: '402-1463928-4', banco: 'Banco Popular', cuenta: '833224553', img: 'assets/img/Popular-victor.png' },
    { id: 'maria', nombre: 'María R. García', cedula: '402-1367889-5', banco: 'Banco BHD', cuenta: '27394890019', img: 'assets/img/BHD-maria.png' },
  ];

  toastServ: ToastService = inject(ToastService);
  copyServ: CopyToClipboardService = inject(CopyToClipboardService);

  async CopyToClipboard(type: 'cuenta' | 'cedula', info: infoBancaria) {
    const text = info[type].replaceAll('-', '');

    this.copyServ
      .copyText(text)
      .then(async () => {
        const msg = type === 'cedula' ? 'Se ha copiado la cédula al portapapeles' : 'Se ha copiado el no. de cuenta al portapapeles';
        this.toastServ.show(toastType.info, msg);

        const className = ['animate__animated', 'animate__tada'];
        fun.ModificarClass(`#${info.id}-${type}`, className, 'add');
        await fun.sleep(1000);
        fun.ModificarClass(`#${info.id}-${type}`, className, 'remove');
      })
      .catch((err) => {
        const msg = `Ha ocurrido un error copiando la ${type}, al portapapeles`;
        this.toastServ.show(toastType.error, msg);
      });
  }
}
