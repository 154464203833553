<section class="gallery-section">
  <div class="limiter" [photoGalleryGroup]="photoGalleryOptions">
    @for (img of galleryImgs; track img) {
    <div class="img-gallery" [ngStyle]="{ 'padding-bottom': getAspectRatio(img) }">
      <div class="waiting">
        <span class="custom-loader"></span>
      </div>

      <img [src]="'assets/img/' + img.name + '.jpg'" [alt]="'foto de galeria ' + img.name" [photoGallery]="'assets/img/' + img.name + '.jpg'" (load)="img.loaded = true" />
    </div>
    }
  </div>
</section>
