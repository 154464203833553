import { ObjectVar } from './../../../core/typescript/constants';
export enum animatedIcon {
  ubication = 'ubication',
  success = 'success',
  information = 'information',
  church = 'church',
  champagne = 'champagne',
  music = 'music',
  gift = 'gift',
  rings = 'rings',
  literature = 'literature',
  letter = 'letter',
  musicPentagram = 'musicPentagram',
  socialPage = 'socialPage',
  birrete = 'birrete',
  corazon = 'corazon',
  home = 'home',
  anilloGlow = 'anilloGlow',
}

export const animatedIconPath: ObjectVar<animatedIcon, string> = {
  [animatedIcon.ubication]: 'assets/gif/earth.gif',
  [animatedIcon.success]: 'assets/gif/success.gif',
  [animatedIcon.information]: 'assets/gif/information.gif',
  [animatedIcon.church]: 'assets/gif/church.gif',
  [animatedIcon.champagne]: 'assets/gif/champagne.gif',
  [animatedIcon.music]: 'assets/gif/music.gif',
  [animatedIcon.rings]: 'assets/gif/rings.gif',
  [animatedIcon.gift]: 'assets/gif/gift.gif',
  [animatedIcon.literature]: 'assets/gif/literature.gif',
  [animatedIcon.letter]: 'assets/gif/letter.gif',
  [animatedIcon.musicPentagram]: 'assets/gif/music_pentagram.gif',
  [animatedIcon.socialPage]: 'assets/gif/social_page.gif',
  [animatedIcon.birrete]: 'assets/gif/birrete.gif',
  [animatedIcon.corazon]: 'assets/gif/corazon.gif',
  [animatedIcon.home]: 'assets/gif/home.gif',
  [animatedIcon.anilloGlow]: 'assets/gif/anillo-glow.gif',
};
