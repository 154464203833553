@if (activityServ.currentGuest()) {
    <div class="fade-in" style="--fade_in_duration: 4s">
    <invitation-body></invitation-body>
    </div>
} @else {
    <welcome-page></welcome-page>
}

<div id="auto-toggle-btn" class="audio-control btn btn-primary circular" (click)="toggleAudio()">
  <div class="position-absolute">
    <animated-icon [icon]="animatedIcon.music" width="40px" height="40px"></animated-icon>
  </div>
</div>
