import { LocalstorageService } from '../../../core/services/localstorage.service';
import { Component, inject } from '@angular/core';
import { ActivityService } from '../../../core/services/activity.service';
import { FormsModule } from '@angular/forms'; // Importa FormsModule
import { fun } from '../../../core/services/functions.service';
import { FormInputDirective } from '../../../core/directives/form-input.directive';
import { LSCurrentGuestId } from '../../../core/models/guest.model';
import { AudioService } from '../../../core/services/audio.service';
import { LSSignInTime } from '../../../core/models/date.model';

@Component({
  selector: 'welcome-page',
  standalone: true,
  imports: [FormsModule, FormInputDirective],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
})
export class WelcomeComponent {
  guestCode: string = '';
  activityServ: ActivityService = inject(ActivityService);
  LocalstorageServ: LocalstorageService = inject(LocalstorageService);
  audioServ: AudioService = inject(AudioService);

  openCodeDialog() {
    const dialog = document.querySelector('#code-dialog');
    dialog?.setAttribute('open', '');

    const dialogMask = document.querySelector('#dialog-mask');
    dialogMask?.classList.add('show');

    if (!this.audioServ.isPlaying && !this.audioServ.userStopAudio) this.audioServ.playAudio();
  }

  closeCodeDialog() {
    const guests = this.activityServ.allGuest() ?? [];
    const guestFound = guests.find((guest) => guest.guestCode.toLowerCase() == this.guestCode?.toLowerCase());

    if (fun.hasValue(this.guestCode) && fun.hasValue(guests) && fun.hasValue(guestFound) && guestFound) {
      this.activityServ.currentGuest.set(guestFound);
      this.LocalstorageServ.setItem(LSCurrentGuestId, guestFound.id);

      const fechaActual = new Date().getTime();
      this.LocalstorageServ.setItem(LSSignInTime, `${fechaActual}`);

      this.hideDialog();
    } else {
      const inputGuestCode = document.querySelector('#input-guest-code');
      const guestCodeMsg = document.querySelector('#msgContainer');

      inputGuestCode?.classList.add('invalid');

      if (guestCodeMsg) {
        if (fun.hasValue(this.guestCode)) {
          guestCodeMsg.textContent = 'El Código introducido es invalido';
        } else {
          guestCodeMsg.textContent = 'Debe de introducir el código';
        }
      }
    }
  }

  hideDialog() {
    const dialog = document.querySelector('#code-dialog');
    dialog?.removeAttribute('open');

    const dialogMask = document.querySelector('#dialog-mask');
    dialogMask?.classList.remove('show');
  }
}
