import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { Component, inject } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { CopyToClipboardService } from '../../../../../core/services/copy-to-clipboard.service';
import { fun } from '../../../../../core/services/functions.service';
import { ToastService, toastType } from '../../../../../core/services/toast.service';
import { infoBancaria } from '../gift-section/gift-section.model';

@Component({
  selector: 'social-pages',
  standalone: true,
  imports: [AnimatedIconComponent],
  templateUrl: './social-pages.component.html',
  styleUrl: './social-pages.component.scss',
})
export class SocialPagesComponent {
  animatedIcon = animatedIcon;

  socialNetworks = [
    { name: '@vjose1903', photo: 'victor' },
    { name: '@maria_g2612', photo: 'maria' },
  ];

  filters = [
    {
      name: 'Marco',
      photos: [
        { path: 'PortraitV&M-1', url: 'https://www.instagram.com/ar/2105224066602400/' },
        { path: 'PortraitV&M-2', url: 'https://www.instagram.com/ar/1119569183100493/' },
      ],
    },
    {
      name: 'Marca de Agua',
      photos: [
        { path: 'PortraitV&M-4', url: 'https://www.instagram.com/ar/1083295853419833/' },
        { path: 'PortraitV&M-3', url: 'https://www.instagram.com/ar/1271953760596104/' },
      ],
    },
    {
      name: 'Iniciales',
      photos: [
        { path: 'PortraitV&M-5', url: 'https://www.instagram.com/ar/8703131356389898/' },
        { path: 'PortraitV&M-6', url: 'https://www.instagram.com/ar/1102968594489392/' },
      ],
    },
    {
      name: 'Lentes',
      photos: [
        { path: 'PortraitV&M-8', url: 'https://www.instagram.com/ar/1081330327025107/' },
        { path: 'PortraitV&M-7', url: 'https://www.instagram.com/ar/853417196722045/' },
      ],
    },
  ];

  toastServ: ToastService = inject(ToastService);
  copyServ: CopyToClipboardService = inject(CopyToClipboardService);

  async CopyToClipboard(info: any) {
    this.copyServ
      .copyText(info.name)
      .then(async () => {
        const msg = `${info.name} Se ha copiado`;
        this.toastServ.show(toastType.info, msg);

        const className = ['animate__animated', 'animate__tada'];
        fun.ModificarClass(`#${info.photo}-info`, className, 'add');
        await fun.sleep(1000);
        fun.ModificarClass(`#${info.photo}-info`, className, 'remove');
      })
      .catch((err) => {
        console.log('err ', err);

        const msg = `Ha ocurrido un error copiando la red social`;
        this.toastServ.show(toastType.error, msg);
      });
  }

  redirectToInstagramFilter(url: string) {
    window.open(url, '_blank');
  }
}
