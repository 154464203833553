<section class="limiter mb-4">
  <div class="d-flex flex-column align-items-center gap-2 mb-1">
    <animated-icon [icon]="animatedIcon.ubication"></animated-icon>
    <h1 class="mb-0 text-f-candle text-s-40 text-shadow">@if (activityServ.currentGuest()?.goToParty) { Ubicaciones } @else { Ubicacion }</h1>
  </div>
  <small class="text-center d-block w-100">
    @if (activityServ.currentGuest()?.goToParty) { No te pierdas de camino, aquí están las ubicaciones de donde celebraremos nuestra boda } @else { No te pierdas de camino, aquí está la ubicación de donde celebraremos nuestra boda }
  </small>

  <div class="grid-cards">
    <div class="custom-card">
      <div class="custom-card-header">
        <div class="d-flex justify-content-between justify-md-content-start align-items-center gap-2">
          <div class="d-flex flex-column">
            <div class="ubication-title">Ceremonia</div>
            <div class="ubication-name">Parroquia Santísima Trinidad</div>
          </div>

          <animated-icon [icon]="animatedIcon.church"></animated-icon>
        </div>

        <img src="assets/img/santisima-trinidad.jpg" alt="Santisima Trinidad" class="img-fluid d-block place-img" />
      </div>

      <div class="custom-card-body">
        <div class="ubication-reason d-none d-md-block" [innerHTML]="reasonCelebration"></div>
        <div class="separator d-none d-md-flex"></div>
        <div class="map-card">
          <div class="waiting">
            <span class="custom-loader dark"></span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.5512315783253!2d-70.52367632401078!3d19.214793647599034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb02b7eb3b638d1%3A0x8d73aff4d52a6de8!2sParroquia%20Sant%C3%ADsima%20Trinidad!5e0!3m2!1ses-419!2sdo!4v1728070697175!5m2!1ses-419!2sdo"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="custom-card-footer">
        <div class="d-flex d-md-none justify-content-end pt-2">
          <button type="button" class="btn btn-primary" style="float: inline-end" (click)="openDialog('celebration')">Ver Más</button>
        </div>
      </div>
    </div>

    @if (activityServ.currentGuest()?.goToParty) {
    <div class="custom-card">
      <div class="custom-card-header">
        <div class="d-flex justify-content-between align-items-center gap-2">
          <div class="d-flex flex-column">
            <div class="ubication-title">Recepción</div>
            <div class="ubication-name">Club Cerro Alto</div>
          </div>

          <animated-icon [icon]="animatedIcon.champagne" width="70px" height="70px"></animated-icon>
        </div>

        <img src="assets/img/cerro-alto.jpg" alt="Cerro Alto" class="img-fluid d-block place-img" />
      </div>

      <div class="custom-card-body reverse-body">
        <div class="ubication-reason d-none d-md-block" [innerHTML]="reasonReception"></div>
        <div class="separator d-none d-md-flex"></div>
        <div class="map-card">
          <div class="waiting">
            <span class="custom-loader dark"></span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.9007346078834!2d-70.49783898424337!3d19.176423128060588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb02bc302759f13%3A0x9322c8bd7b7d1130!2sClub%20Cerro%20Alto%20La%20Vega!5e0!3m2!1ses-419!2sdo!4v1728071201407!5m2!1ses-419!2sdo"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div class="custom-card-footer">
        <div class="d-flex d-md-none justify-content-end pt-2">
          <button type="button" class="btn btn-primary" style="float: inline-end" (click)="openDialog('reception')">Ver Más</button>
        </div>
      </div>
    </div>
    }
  </div>
</section>

<div id="dialog-mask" class="dialog-mask" (click)="hideDialog()">
  <dialog id="place-dialog" (click)="$event.stopPropagation()">
    <div class="d-block">
      <span class="d-block" [innerHTML]="selectedPlaceMsg"></span>
      <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="hideDialog()">OK</button>
    </div>
  </dialog>
</div>
