import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivityService } from '../../../../../core/services/activity.service';
import { GuestClass } from '../../../../../core/clases/guest.class';
import { TitleCasePipe } from '@angular/common';
import { ToastService, toastType } from '../../../../../core/services/toast.service';

@Component({
  selector: 'message-box',
  standalone: true,
  imports: [AnimatedIconComponent, FormsModule, TitleCasePipe],
  templateUrl: './message-box.component.html',
  styleUrl: './message-box.component.scss',
})
export class MessageBoxComponent implements OnInit {
  animatedIcon = animatedIcon;
  message!: string;

  activityServ: ActivityService = inject(ActivityService);
  toastServ: ToastService = inject(ToastService);

  get currentGuest(): GuestClass {
    return this.activityServ.currentGuest()!;
  }

  ngOnInit(): void {
    if (this.currentGuest) {
      this.message = this.currentGuest.message;
    }
  }

  async sendMessage() {
    if (this.currentGuest) {
      try {
        this.currentGuest.message = this.message.trim();

        await this.currentGuest.update();

        const guestUpdated = this.activityServ.allGuest()?.find((guest) => guest.id == this.currentGuest.id);

        if (guestUpdated) {
          guestUpdated.message = this.currentGuest.message;

          if (guestUpdated.id === this.currentGuest.id) this.activityServ.currentGuest.set(guestUpdated);
        }

        this.toastServ.show(toastType.success, 'Gracias por enviar tu mensaje');
      } catch (error) {
        console.log(error);
        this.currentGuest.message = '';
      }
    }
  }
}
