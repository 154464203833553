<section class="spotify-section">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center gap-2 mb-1">
      <animated-icon [icon]="animatedIcon.musicPentagram"></animated-icon>
      <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Nuestra Musica</h1>
    </div>
    <small class="text-center d-block w-100"> ¿Listos para el ritmo? Aquí tienes nuestra playlist: la música que hará vibrar el gran día </small>

    <div class="w-100 py-5">
      <div class="spotify-container">
        <div class="waiting">
          <span class="custom-loader"></span>
        </div>
        <iframe
          style="border-radius: 12px"
          src="https://open.spotify.com/embed/playlist/3So329TeoaMSwqJ6g7VynA?utm_source=generator&theme=0"
          width="100%"
          height="352"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</section>
