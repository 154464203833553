/// <reference path="../../../../../typings/flip.d.ts" />
import { AfterViewInit, Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import Tick from '@pqina/flip';

import { esFlipConstants } from './flip-clock';
import { ActivityService } from '../../../../../core/services/activity.service';
import { ObjectVar } from '../../../../../core/typescript/constants';
import { fun } from '../../../../../core/services/functions.service';
import { DateUtils } from '../../../../../core/typescript/date.utils';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'flip-clock',
  standalone: true,
  imports: [LottieComponent],
  templateUrl: './flip-clock.component.html',
  styleUrl: './flip-clock.component.scss',
})
export class FlipClockComponent implements OnInit, AfterViewInit, OnDestroy {
  activityServ: ActivityService = inject(ActivityService);
  ngZone: NgZone = inject(NgZone);
  tickFlipInterval: any;

  options: AnimationOptions = { path: '/assets/lottie/fireworks.json', loop: true, autoplay: true };
  private animationItem: AnimationItem | undefined;

  ngOnInit(): void {
    this.initializeTick();
  }

  initializeTick() {
    const activity = this.activityServ.currentActivity()!;
    const _this = this;
    Tick.DOM.create(document.querySelector('.tick'), {
      didInit: function (tick: any) {
        var counter = Tick.count.down(activity.date);

        counter.onupdate = function (value: any) {
          tick._constants = fun.objectKeys(tick._constants).reduce((acu: ObjectVar<string, string>, item: string) => {
            acu[item] = (esFlipConstants as any)[item];
            return acu;
          }, {});

          tick.value = value;
        };

        counter.onended = function () {
          _this.handleTickFinish();
        };
      },
    });
  }

  ngAfterViewInit(): void {
    const credits: HTMLElement | null = document.querySelector('.tick-credits');
    if (credits) credits.style.display = 'none';

    this.ngZone.runOutsideAngular(() => {
      this.tickFlipInterval = setInterval(() => {
        const flipPanels: any = document.querySelectorAll('.tick-flip-panel');

        if (flipPanels && fun.hasValue(flipPanels)) {
          Array.from(flipPanels).forEach((flipPanel: any) => {
            (flipPanel.style as any)['background-color'] = '#6e3e0f';
          });
        }
      }, 100);
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.tickFlipInterval);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  handleTickFinish() {
    const tickContainer = document.querySelector('.tick-container');
    const message = document.querySelector('.tick-onended-message');

    if (message) {
      message.classList.add('show');
    }
    if (tickContainer) {
      tickContainer.classList.add('d-none');
    }
  }
}
