<section class="gift-section">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center gap-2 mb-1">
      <animated-icon [icon]="animatedIcon.gift" ></animated-icon>
      <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Regalos</h1>
    </div>
    <small class="text-center d-block w-100"> Lo más importante para nosotros es que estén presentes en nuestra boda y disfruten este día junto a nosotros. Sin embargo, si desean hacernos un obsequio, les agradeceríamos que lo hicieran mediante una transferencia bancaria. ¡De antemano gracias! </small>

    <div class="row">
      @for (datoBancario of datosBancarios; track $index) {
      <div class="col-12 col-md-6 px-2">
        <div class="gift-card custom-card bg-white card-rounded card-shadow shadow-brown" [ngClass]="{ 'slide-right': $index % 2 == 0, 'slide-left': $index % 2 != 0 }">
          <div class="custom-card-header ">
            <div class="gift-card-container">
              <img [id]="datoBancario.id + '-cuenta'" [src]="datoBancario.img" alt="cuenta BHD Victor" class="img-fluid gift-card c-pointer" (click)="CopyToClipboard('cuenta', datoBancario)" />
            </div>
          </div>
          <div class="custom-card-body p-3">
            <div class="w-100">
              <span class="text-f-proelium text-c-tertiary transferencia mb-3 d-block text-center"> Transferencia Bancaria </span>
            </div>

            <div class="text-f-glamour text-s-25 mt-1 d-block text-center white-space-nowrap">{{ datoBancario.nombre }}</div>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <div [id]="datoBancario.id + '-cedula'" class="d-flex align-items-center gap-2 c-pointer" (click)="CopyToClipboard('cedula', datoBancario)">
                <span class="custom-tag c-primary py-1 px-2 text-s-12"> Cédula </span>
                <div class="text-s-14 text-c-black-50 white-space-nowrap">{{ datoBancario.cedula }}</div>
              </div>
              <div class="text-s-16 white-space-nowrap text-w-500">{{ datoBancario.banco }}</div>
            </div>
          </div>
          <div class="custom-card-footer"></div>
        </div>
      </div>
      }
    </div>
  </div>
</section>
