import { MainComponent } from './pages/main/main.component';
import { GuestListComponent } from './pages/guest-list/guest-list.component';
import { Routes } from '@angular/router';
import { AssistanceListComponent } from './pages/assistance-list/assistance-list.component';

export const routes: Routes = [
  { path: 'assistance-list', component: AssistanceListComponent }, 
  { path: 'guest-list', component: GuestListComponent }, 
  { path: '', component: MainComponent }, 
  { path: '', redirectTo: '', pathMatch: 'full' }, 
  { path: '**', redirectTo: '' }, 
];
