<section class="message-section">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center gap-2 mb-1">
      <animated-icon [icon]="animatedIcon.letter"></animated-icon>
      <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Buzon de Mensajes</h1>
    </div>
    <small class="text-center d-block w-100"> ¡Deja tu huella! Escribe un mensaje que nos haga sonreír </small>

    <div class="custom-card message bg-white card-rounded card-shadow shadow-brown my-4">
      <div class="custom-card-header message-header"></div>
      <div class="custom-card-body">
        <div class="widget">
          <div class="widget-header">
            @if (currentGuest) {
            <span class="mb-3">
              <span class="text-s-16"> De: </span>
              <span class="text-s-30 text-f-tangerine text-w-500"> {{ currentGuest.name | titlecase }}</span>
            </span>
            }
          </div>
          <div class="widget-content">
            <div class="d-flex flex-column">
              <textarea [(ngModel)]="message" class="form-textarea" rows="8" name="" id="" placeholder="Escribe algo aquí..."></textarea>
              <small class="text-c-danger" id="msgContainer"></small>
            </div>

            <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="sendMessage()">OK</button>
          </div>
        </div>
      </div>
      <div class="custom-card-footer"></div>
    </div>
  </div>
</section>
