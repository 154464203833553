<div class="widget widget-page">
  <div class="widget-header">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex gap-3">
        <h3 class="mb-0">Lista de Asistencia</h3>
      </div>

      <div class="d-flex gap-3">
        <button type="button" class="btn btn-light" style="float: inline-end" (click)="navigateBack()">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </button>

        @if (sessionActive) {
        <button type="button" class="btn btn-light" style="float: inline-end" (click)="deleteSession()">
          <i class="fa-solid fa-right-from-bracket"></i>
        </button>
        }
      </div>
    </div>
  </div>
  <div class="widget-content p-2 p-md-3 p-lg-5">
    @if (sessionActive) {
    <div class="w-100 h-100 custom-card card-shadow card-rounded overflow-hidden">
      <div class="custom-card-body">
        <p-table
          #GuestListTable
          [value]="guestList"
          [loading]="loading"
          [scrollable]="true"
          scrollHeight="flex"
          [customSort]="true"
          [rows]="guestList.length"
          [paginator]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="46"
          styleClass="p-datatable-sm"
          [globalFilterFields]="['name', 'guestCode']"
          currentPageReportTemplate="{totalRecords} Invitados"
          [showCurrentPageReport]="true"
          [showJumpToPageDropdown]="false"
          [showJumpToPageInput]="false"
          [showFirstLastIcon]="false"
          [showPageLinks]="false"
          (sortFunction)="customSort($event)"
        >
          <ng-template pTemplate="caption">
            <div class="d-flex justify-content-between align-items-end">
              <div class="d-flex column-gap-3 row-gap-2 flex-wrap">
                <div class="d-flex flex-column">
                  <small>Filtro</small>
                  <input FormInput [required]="true" placeholder="Buscar..." [(ngModel)]="filterWord" class="form-input no-validate size-lg" (input)="GuestListTable.filterGlobal(filterWord, 'contains')" />
                </div>

                <div class="d-flex flex-column">
                  <small>presente</small>
                  <p-selectButton [options]="selectButtonOptions" [(ngModel)]="currentFilterPresent" optionLabel="label" optionValue="value" (ngModelChange)="customFilter('present')" />
                </div>
              </div>

              <div class="d-block">
                <button type="button" class="btn btn-light" style="float: inline-end" (click)="reloadGuestList()">
                  <i class="fa-solid fa-arrows-rotate"></i>
                </button>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name">
                Nombre
                <p-sortIcon field="name" />
              </th>
              <th style="width: 25%">
                <div class="w-100 d-flex justify-content-center">Presente</div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-guest>
            <tr>
              <td>
                {{ guest.name }}
              </td>
              <td>
                <div class="w-100 d-flex justify-content-center">
                  <i class="pi p-2 c-pointer" [ngClass]="{ 'text-c-green pi-check-circle': guest.present, 'text-c-danger pi-times-circle': !guest.present }" (click)="updateGuestProp('present', guest)"></i>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">No hay invitados con las especificaciones introducidas.</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    } @else {
    <div class="w-100 h-100 d-flex">
      <div class="admin-card custom-card card-shadow card-rounded overflow-hidden">
        <div class="custom-card-header">
          <p class="mb-0">Introduce la contraseña</p>
        </div>
        <div class="custom-card-body">
          <div class="d-flex flex-column">
            <span class="input-icon-right">
              <input FormInput [required]="true" [type]="showPassword ? 'text' : 'password'" id="input-admin-code" [(ngModel)]="adminCode" class="form-input" (keydown.enter)="saveSession()" autofocus />
              <i class="icon c-pointer fa-regular p-2" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" (click)="showPassword = !showPassword"></i>
            </span>
            <small class="text-c-danger" id="msgContainer"></small>
          </div>
        </div>

        <div class="custom-card-footer px-3 pb-3">
          <button type="button" class="btn btn-text-primary mt-3" style="float: inline-end" (click)="saveSession()">OK</button>
        </div>
      </div>
    </div>
    }
  </div>
</div>
