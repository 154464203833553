import { guestI } from '../models/guest.model';
import { fun } from '../services/functions.service';
import { FirebaseRequest } from '../typescript/firebaseRequest';

export class GuestClass extends FirebaseRequest<guestI, any> {
  id: string = '';
  activity_id: string = '';
  name: string = '';
  guestCode: string = '';
  confirmed: boolean = false;
  goToParty: boolean = false;
  showConfirm: boolean = false;
  isStaff: boolean = false;
  present: boolean = false;
  invitationSend: boolean = false;
  group?: number | undefined = undefined;
  message: string = '';

  constructor(activity_id: string, params?: guestI) {
    super();
    this.firebaseCollectionName = `activity/${activity_id ?? ''}/guest`;

    if (fun.hasValue(params) && params) {
      this.id = fun.hasValue(params?.id) ? params.id : '';
      this.activity_id = fun.hasValue(activity_id) ? activity_id : '';
      this.name = fun.hasValue(params?.name) ? params.name : '';
      this.guestCode = fun.hasValue(params?.guestCode) ? params.guestCode : '';
      this.confirmed = fun.hasValue(params?.confirmed) ? params.confirmed : false;
      this.goToParty = fun.hasValue(params?.goToParty) ? params.goToParty : false;
      this.showConfirm = fun.hasValue(params?.showConfirm) ? params.showConfirm : false;
      this.invitationSend = fun.hasValue(params?.invitationSend) ? params.invitationSend : false;
      this.group = fun.hasValue(params?.group) ? params.group : undefined;
      this.isStaff = fun.hasValue(params?.isStaff) ? params.isStaff : false;
      this.present = fun.hasValue(params?.present) ? params.present : false;
      this.message = fun.hasValue(params?.message) && params.message ? params.message : '';
    }
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      guestCode: this.guestCode,
      confirmed: this.confirmed,
      goToParty: this.goToParty,
      showConfirm: this.showConfirm,
      group: this.group,
      message: this.message,
      invitationSend: this.invitationSend,
      present: this.present,
    } as guestI;
  }

  async getByActivityIdAndId(id: string) {
    try {
      const guest = await this._getOne({ id });
      return new GuestClass(this.activity_id, guest);
    } catch (error) {
      return undefined;
    }
  }

  async update() {
    await this._updateOne({ id: this.id, data: this.toJson() });
  }

  async create(data?: guestI) {
    const dataToCreate = data ?? this.toJson();
    await this._createOne({ id: '', data: dataToCreate });
  }
}
