import { Component, inject } from '@angular/core';
import { ActivityService } from '../../../../../core/services/activity.service';

@Component({
  selector: 'invitation-detail',
  standalone: true,
  imports: [],
  templateUrl: './invitation-detail.component.html',
  styleUrl: './invitation-detail.component.scss',
})
export class InvitationDetailComponent {
  activityServ: ActivityService = inject(ActivityService);

  addEventToGoogleCalendar() {
    const activity = this.activityServ.currentActivity()!;

    const event = {
      title: 'Boda María & Victor 💍',
      start: activity.date,
      end: activity.date,
      details: 'Nos encantaría que nos acompañaras en la celebración de nuestra boda. Será un día especial lleno de amor, alegría y buenos momentos. ¡Te esperamos para compartir este momento único con nosotros!',
      location: 'Parroquia Santísima Trinidad, La Vega',
    };

    const startTime = event.start.toISOString().replace(/-|:|\.\d\d\d/g, '');
    const endTime = event.end.toISOString().replace(/-|:|\.\d\d\d/g, '');

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${startTime}/${endTime}&details=${encodeURIComponent(event.details)}&location=${encodeURIComponent(event.location)}`;

    
    window.open(url, '_blank');
  }
}
