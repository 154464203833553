import { ToastService, toastType } from '../../../../../core/services/toast.service';
import { FormsModule } from '@angular/forms';
import { Component, computed, inject, OnInit } from '@angular/core';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { ActivityService } from '../../../../../core/services/activity.service';
import { guestI } from '../../../../../core/models/guest.model';
import { TitleCasePipe, CommonModule } from '@angular/common';
import { fun } from '../../../../../core/services/functions.service';
import { GuestClass } from '../../../../../core/clases/guest.class';
import { HtmlElementsService } from '../../../../../core/services/html-elements.service';
import { LocalstorageService } from '../../../../../core/services/localstorage.service';

@Component({
  selector: 'confirm-section',
  standalone: true,
  imports: [CommonModule, FormsModule, AnimatedIconComponent, TitleCasePipe],
  templateUrl: './confirm-section.component.html',
  styleUrl: './confirm-section.component.scss',
})
export class ConfirmSectionComponent implements OnInit {
  animatedIcon = animatedIcon;

  activityServ: ActivityService = inject(ActivityService);
  htmlServ: HtmlElementsService = inject(HtmlElementsService);
  localStorageServ: LocalstorageService = inject(LocalstorageService);
  toastServ: ToastService = inject(ToastService);

  fun = fun;

  get currentGuest(): GuestClass {
    return this.activityServ.currentGuest()!;
  }

  guestGroup = computed(() => {
    return this.activityServ.allGuest()?.filter((guest: guestI) => guest.group == this.currentGuest.group && guest.id != this.currentGuest.id) ?? [];
  });

  constructor() {}

  ngOnInit(): void {}

  async confirmAssistance(guest: GuestClass | null) {
    if (guest) {
      try {
        await guest.update();

        const guestUpdated = this.activityServ.allGuest()?.find((guest) => guest.id == guest.id);

        if (guestUpdated) {
          guestUpdated.confirmed = guest.confirmed;
          if (guestUpdated.id === this.currentGuest.id) this.activityServ.currentGuest.set(guestUpdated);
        }

        if (guest.confirmed) this.toastServ.show(toastType.success, 'Gracias por confirmar tu asistencia');
        else this.toastServ.show(toastType.error, 'Has cancelado tu confirmación de asistencia');
      } catch (error) {
        console.log(error);
        guest.confirmed = !guest.confirmed;
      }
    }
  }
}
