<section class="details">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="w-100 text-center">
        <span class="first-msg text-f-tangerine text-center text-s-40">Uno solo puede ser vencido, pero dos pueden defenderse; y si otro une sus fuerzas ¡Cordón de tres dobleces no se rompe fácilmente!</span>
        &nbsp;&nbsp; <small class="text-s-13 cita-biblica text-c-black-50">Eclesiastés 4:12 </small>
      </div>

      <small class="text-center text-w-200 text-s-16 mt-5"> Por tal razón te invitamos a ser testigo de la unión de nuestro amor, celebrando juntos el compromiso que haremos ante Dios y nuestros seres queridos </small>

      <h1 class="text-f-glamour text-s-50 text-center my-5 text-shadow no-select">¡ Nos Casamos !</h1>

      <div class="date-spec no-select">
        <span class="line-both text-f-vogue"> SÁBADO </span>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <span class="main text-f-vogue">14</span>
          <span class="sub-main text-f-vogue">2024</span>
        </div>
        <span class="line-both text-f-vogue">DICIEMBRE </span>
      </div>

      <h4 class="hour-detail">03:30 PM</h4>

      <button type="button" class="btn btn-primary w-fit-content mb-3 schedule-btn" (click)="addEventToGoogleCalendar()">
        <div class="d-flex gap-2 flex-nowrap">
          Agendar
          <img src="assets/img/google_calendar.png" alt="google_calendar" class="img-fluid google-calendar" />
        </div>
      </button>

      <span class="text-center text-w-200">
        Lo nuestro empezó con un jocoso mensaje de texto y he aquí casi 6 años después; estamos orgullosos de la relación que hemos construido y sobre todo súper emocionados de hacer nuestra boda de sueño una realidad.
      </span>
    </div>
  </div>
</section>
