import { CommonModule } from '@angular/common';
import { GalleryOptions } from '../../../../../../node_modules/@twogate/ngx-photo-gallery/lib/interfaces/photoswipe';
import { Component } from '@angular/core';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { ObjectVar } from '../../../../../core/typescript/constants';

@Component({
  selector: 'gallery',
  standalone: true,
  imports: [PhotoGalleryModule, CommonModule],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
})
export class GalleryComponent {
  galleryImgs: Array<{ name: string; width: number; height: number; loaded: boolean }> = [
    { name: 'gallery_1', width: 2048, height: 3074, loaded: false },
    { name: 'gallery_2', width: 1696, height: 2544, loaded: false },
    { name: 'gallery_6', width: 2048, height: 3072, loaded: false },
    { name: 'gallery_8', width: 2048, height: 1364, loaded: false },
    { name: 'gallery_4', width: 2048, height: 1365, loaded: false },
    { name: 'gallery_5', width: 2048, height: 3072, loaded: false },
    { name: 'gallery_3', width: 2048, height: 3072, loaded: false },
    { name: 'gallery_7', width: 2048, height: 3072, loaded: false },
  ];

  photoGalleryOptions: GalleryOptions = {
    arrowEl: true,
    zoomEl: true,
    indexIndicatorSep: ' - ',
  };

  getAspectRatio(img: { name: string; width: number; height: number; loaded: boolean }) {
    return img.loaded ? '0' : `${(img.height / img.width) * 100}%`;
  }
}
