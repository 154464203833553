import { Component, inject, Input, OnInit } from '@angular/core';
import { animatedIcon, animatedIconPath } from './animated-icon.model';
import { CommonModule } from '@angular/common';
import { VisibleDirective } from '../../../core/directives/visible.directive';
import { HtmlElementsService } from './../../../core/services/html-elements.service';
import { fun } from '../../../core/services/functions.service';

@Component({
  selector: 'animated-icon',
  standalone: true,
  imports: [CommonModule, VisibleDirective],
  templateUrl: './animated-icon.component.html',
  styleUrl: './animated-icon.component.scss',
})
export class AnimatedIconComponent implements OnInit {
  @Input() icon!: animatedIcon;
  @Input() width: string = '60px';
  @Input() height: string = '60px';
  @Input() duration!: number;

  iconPath!: string;
  htmlServ = inject(HtmlElementsService);

  ngOnInit(): void {
    this.iconPath = animatedIconPath[this.icon];
  }

  stopAnimation() {
    if (this.duration) {
      const gif: HTMLElement | null = document.querySelector(`#icon-${this.icon}`);

      if (gif) {
        fun.sleep(this.duration).then(() => {
          (gif as any).src = this.iconPath.replaceAll('.gif', '.png');
        });
      }
    }
  }
}
