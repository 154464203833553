import { animatedIcon } from '../../../../components/animated-icon/animated-icon.model';
import { AnimatedIconComponent } from '../../../../components/animated-icon/animated-icon.component';
import { Component } from '@angular/core';

@Component({
  selector: 'spotify-album',
  standalone: true,
  imports: [AnimatedIconComponent],
  templateUrl: './spotify-album.component.html',
  styleUrl: './spotify-album.component.scss',
})
export class SpotifyAlbumComponent {
  animatedIcon = animatedIcon;
}
