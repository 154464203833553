<section class="confirm-section py-4">
  <div class="limiter">
    <div class="d-flex flex-column align-items-center gap-2 mb-1">
      <animated-icon [icon]="animatedIcon.success" width="80px" height="80px"></animated-icon>
      <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Confirmar Asistencia</h1>
    </div>
    <small class="text-center d-block w-100"> Tu presencia en nuestra boda es muy especial. <strong class="text-underline"> Favor confirmar tu asistencia</strong>. ¡Esperamos celebrarlo juntos! </small>

    <div class="custom-card confirm bg-white card-rounded card-shadow shadow-brown mt-4">
      <div class="custom-card-header confirm-header"></div>
      <div class="custom-card-body">
        <div class="widget">
          <div class="widget-header mb-2">
            <p>Para confirmar tú asistencia marca el check al lado de tu nombre</p>
          </div>
          <div class="widget-content">
            @if (currentGuest) {
            <div class="d-flex justify-content-between align-items-center">
              <h1 class="text-s-30 text-f-tangerine text-w-500 mb-0">
                {{ currentGuest.name | titlecase }}
              </h1>

              <input class="apple-switch" type="checkbox" [(ngModel)]="currentGuest.confirmed" (ngModelChange)="confirmAssistance(currentGuest)" />
            </div>
            }
          </div>

          <div class="widget-footer">
            @if (fun.hasValue(guestGroup()) && guestGroup().length > 0) {

            <div class="guest-group-confirmation">
              <div class="widget mt-5">
                <div class="widget-header">
                  <small class="text-c-secondary">También puedes confirmar a las personas que te acompañaran</small>
                </div>
                <div class="widget-content pt-3">
                  @for (guest of guestGroup(); track $index) {
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <span class="text-s-25 text-f-tangerine text-w-500 mb-0">
                      {{ guest.name | titlecase }}
                    </span>

                    <input class="apple-switch" type="checkbox" [(ngModel)]="guest.confirmed" (ngModelChange)="confirmAssistance(guest)" />
                  </div>
                  }
                </div>
              </div>
            </div>

            }
          </div>
        </div>
      </div>
      <div class="custom-card-footer">
        <small class="text-c-black-30"> Las invitaciones no son transferibles y son individuales, en la entrada del evento se les preguntará su nombre; si no esta en la lista no podrá ingresar</small>
      </div>
    </div>
  </div>
</section>
