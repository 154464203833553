<div class="invitation-body">
  <header>
    <div class="main-header-div">
      <div class="d-flex align-items-center-1">
        <animated-icon [icon]="animatedIcon.rings" width="45px" height="45px"></animated-icon>
        <h5 class="main-header-title mb-0 text-s-35 text-w-500 text-shadow">Victor & María</h5>
      </div>
      <button type="button" class="btn btn-light" style="float: inline-end" (click)="exit()">
        <i class="fa-solid fa-right-from-bracket"></i>
      </button>
    </div>
  </header>

  <div id="header" class="header" [ngClass]="{ ios: deviceInfo.browser_parsed === BrowserParsedE.safari }">
    <div class="header-section">
      <h1 [innerHTML]="'Victor<br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;María'"></h1>
      <i class="fa-solid fa-angles-down text-c-white-30 animate__animated animate__bounce animate__delay-2s animate__infinite"></i>
    </div>
  </div>
  <div class="details">
    <invitation-detail></invitation-detail>
  </div>

  <section class="content-visibility-auto">
    <flip-clock></flip-clock>
  </section>

  <main>
    <div class="mt-4">
      <ubications></ubications>
    </div>

    @if (activityServ.currentGuest()?.goToParty && activityServ.currentGuest()?.showConfirm) {
    <div class="content-visibility-auto">
      <confirm-section></confirm-section>
    </div>
    }

    <div class="content-visibility-auto">
      <considerations></considerations>
    </div>

    @if (!noShowGift.includes(activityServ.currentGuest()?.guestCode || '')) {
    <div class="content-visibility-auto">
      <gift-section></gift-section>
    </div>
  }
    <div class="position-relative content-visibility-auto">
      <our-history></our-history>
    </div>

    <div class="content-visibility-auto">
      <social-pages></social-pages>
    </div>

    <div class="content-visibility-auto mt-5">
      <spotify-album></spotify-album>
    </div>

    @if (activityServ.currentGuest()?.goToParty && !activityServ.currentGuest()?.isStaff) {
    <div class="content-visibility-auto mt-5">
      <message-box></message-box>
    </div>
    }

    <div class="content-visibility-auto mt-5">
      <gallery></gallery>
    </div>

    <section class="limiter py-5 content-visibility-auto">
      <p class="text-center mt-5">
        El amor es como un rompecabezas nosotros somos las piezas que, aunque diferentes, se complementan para crear una historia perfecta. Inesperadamente Yo <strong>Victor</strong> encontré a <strong>María</strong> y me di cuenta que es la pieza
        que quiero complementar toda mi vida. Luego de casi 6 años llenos de retos y aventuras confirmamos que nuestro amor es verdadero y que uno de los sueños que nos falta por realizar es entregarlo por completo en manos de nuestro señor.
      </p>

      <div class="animate__animated animate__heartBeat animate__infinite">
        <h1 class="text-f-glamour text-s-55 text-center my-5 text-shadow">¡Te esperamos!</h1>
      </div>
    </section>
  </main>

  <div class="scroll-up-control btn btn-primary circular" [ngClass]="{ ios: deviceInfo.browser_parsed === BrowserParsedE.safari, celular: deviceInfo.device == DeviceE.mobile }" (click)="scrollToTop()">
    <div class="position-absolute">
      <i class="fa-solid fa-arrow-up"></i>
    </div>
  </div>
</div>
