import { animatedIcon } from '../../components/animated-icon/animated-icon.model';
import { Router } from '@angular/router';
import { BrowserParsedE, DeviceE } from '../../../core/models/device-detector.model';
import { CommonModule, JsonPipe } from '@angular/common';
import { DeviceDetectorService } from '../../../core/services/device-detector.service';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { deviceDetectorI } from '../../../core/models/device-detector.model';
import { ActivityService } from '../../../core/services/activity.service';
import { fun } from '../../../core/services/functions.service';
import { LocalstorageService } from '../../../core/services/localstorage.service';

import { AnimatedIconComponent } from '../../components/animated-icon/animated-icon.component';
import { InvitationDetailComponent } from './sections/invitation-detail/invitation-detail.component';
import { FlipClockComponent } from './sections/flip-clock/flip-clock.component';
import { UbicationsComponent } from './sections/ubications/ubications.component';
import { ConfirmSectionComponent } from './sections/confirm-section/confirm-section.component';
import { ConsiderationsComponent } from './sections/considerations/considerations.component';
import { GiftSectionComponent } from './sections/gift-section/gift-section.component';
import { OurHistoryComponent } from './sections/our-history/our-history.component';
import { SocialPagesComponent } from './sections/social-pages/social-pages.component';
import { SpotifyAlbumComponent } from './sections/spotify-album/spotify-album.component';
import { MessageBoxComponent } from './sections/message-box/message-box.component';
import { GalleryComponent } from './sections/gallery/gallery.component';
import { itspartytimeCode } from '../../../core/models/guest.model';

@Component({
  selector: 'invitation-body',
  standalone: true,
  imports: [
    JsonPipe,
    CommonModule,
    AnimatedIconComponent,
    InvitationDetailComponent,
    FlipClockComponent,
    UbicationsComponent,
    ConfirmSectionComponent,
    ConsiderationsComponent,
    GiftSectionComponent,
    OurHistoryComponent,
    SocialPagesComponent,
    SpotifyAlbumComponent,
    MessageBoxComponent,
    GalleryComponent,
  ],
  templateUrl: './invitation-body.component.html',
  styleUrl: './invitation-body.component.scss',
})
export class InvitationBodyComponent implements OnInit, OnDestroy {
  BrowserParsedE = BrowserParsedE;
  DeviceE = DeviceE;
  deviceInfo!: deviceDetectorI;

  fun = fun;

  animatedIcon = animatedIcon;

  private intervalId: any;

  noShowGift = ['fam_vasquez'];

  deviceDetectorServ: DeviceDetectorService = inject(DeviceDetectorService);
  localStorageServ: LocalstorageService = inject(LocalstorageService);
  activityServ: ActivityService = inject(ActivityService);
  router: Router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    this.initializeHeaderSize();

    this.deviceInfo = this.deviceDetectorServ.getDeviceInfo();
  }

  ngOnDestroy(): void {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  initializeHeaderSize() {
    const header: HTMLElement | null = document.querySelector('#header');
    const headerSection: HTMLElement | null = document.querySelector('.header-section');

    if (header && headerSection) {
      header.style.height = `${headerSection.clientHeight * 2}px`;
    }
  }

  exit() {
    this.localStorageServ.clearSesion();
    if (this.activityServ.currentGuest()?.guestCode == itspartytimeCode) {
      this.router.navigateByUrl('/');
      setTimeout(() => {
        window.location.reload();
      }, 250);
    } else {
      window.location.reload();
    }
  }

  scrollToTop(): void {
    fun.scrollTo({ id: 'header', block: 'start', behavior: 'smooth', inline: 'start' });
  }
}
