import { addDoc, collection, doc, getDoc, getDocs, updateDoc } from '@angular/fire/firestore';
import { Ss } from '../services/singleton.service';

export class FirebaseRequest<baseCollection, subCollection> {
  private _firebaseCollectionName!: string;

  set firebaseCollectionName(value: string) {
    this._firebaseCollectionName = value;
  }

  async _getAll(params: referenceParams): Promise<baseCollection[] | subCollection[]> {
    return new Promise<baseCollection[] | subCollection[]>(async (resolve, reject) => {
      try {
        const refString = this.makeEntityRefString(params);
        const entityRef = collection(Ss.firestore, refString);

        const entitySnapshot = await getDocs(entityRef);

        if (entitySnapshot.docs && entitySnapshot.docs.length > 0) {
          const entityItems = entitySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

          resolve(entityItems as baseCollection[] | subCollection[]);
        } else {
          resolve([]);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async _getOne(params: referenceParams): Promise<baseCollection> {
    return new Promise<baseCollection>(async (resolve, reject) => {
      try {
        const refString = this.makeEntityRefString(params);

        const entityRef = doc(Ss.firestore, refString);
        const entitySnapshot = await getDoc(entityRef);

        if (entitySnapshot.exists()) {
          resolve({ ...entitySnapshot.data(), id: entitySnapshot.id } as baseCollection);
        } else {
          reject(new Error('Document does not exist'));
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async _createOne(params: referenceParams): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const refString = this.makeEntityRefString(params);
        const entityRef = collection(Ss.firestore, refString);

        // Crea un nuevo documento en la colección
        await addDoc(entityRef, params.data);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async _updateOne(params: referenceParams) {
    return new Promise<any>(async (resolve, reject) => {
      const refString = this.makeEntityRefString(params);
      const entityRef = doc(Ss.firestore, refString);

      try {
        await updateDoc(entityRef, params.data);
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  }

  makeEntityRefString(params: referenceParams) {
    let refString = `${this._firebaseCollectionName}`;

    if (params.id) refString += `/${params.id}`;
    if (params.subEntity) refString += `/${params.subEntity}`;
    if (params.subEntityId) refString += `/${params.subEntityId}`;

    return refString;
  }
}

export interface referenceParams {
  id: any;
  data?: any;
  subEntity?: string;
  subInterface?: any;
  subEntityId?: any;
}
