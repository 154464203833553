import { activityI } from './../models/activity.model';
import { inject, Injectable, signal } from '@angular/core';
import { LocalstorageService } from './localstorage.service';
import { GuestClass } from '../clases/guest.class';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  localStorageServ: LocalstorageService = inject(LocalstorageService);
  currentActivity = signal<activityI | null>(null);
  currentGuest = signal<GuestClass | null>(null);
  allGuest = signal<GuestClass[] | null>(null);

  get activity_id() {
    return this.currentActivity()?.id || '';
  }
  constructor() {}
}
