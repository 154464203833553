@defer (when activityServ.currentActivity()) {
  
  @if (activityServ.allGuest()) {
    <router-outlet />
  }

} @placeholder (minimum 1000ms){
<div class="w-100 h-100 d-flex flex-column gap-2 align-items-center justify-content-center">
  <img src="assets/img/icon.png" alt="initials" class="img-fluid animate__animated animate__pulse animate__infinite" />
  <h1 class="text-c-tertiary">Cargando...</h1>
</div>
}
