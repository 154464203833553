export const guestList = [
  // { name: 'Victor J. Vásquez',            guestCode: 'victor_g01',      group: 1,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },
  // { name: 'María R. García',              guestCode: 'maría_g01',       group: 1,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'José Fco. Vásquez',            guestCode: 'josé_g02',        group: 2,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Maria Eduviges Santos',        guestCode: 'maria_g02',       group: 2,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Rosaura De Los Santos',        guestCode: 'rosaura_g03',     group: 3,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'José García',                  guestCode: 'josé_g04',        group: 4,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Scarlette Vásquez',            guestCode: 'scarlette_g05',   group: 5,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Cristhian Posso',              guestCode: 'cristhian_g05',   group: 5,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Pura Maria',                   guestCode: 'pura_g05',        group: 5,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Fausto Jose',                  guestCode: 'fausto_g05',      group: 5,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Kathy Vásquez',                guestCode: 'kathy_g06',       group: 6,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Adrian Gutierrez',             guestCode: 'adrian_g06',      group: 6,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Galilea Gutierrez',            guestCode: 'galilea_g06',     group: 6,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Zoraida Hernandez',            guestCode: 'zoraida_g07',     group: 7,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Aaron Vásquez',                guestCode: 'aaron_g07',       group: 7,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Ruben Vásquez',                guestCode: 'ruben_g07',       group: 7,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Ruth Vásquez',                 guestCode: 'ruth_g07',        group: 7,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },
  // { name: 'Rosa Cornelio',                guestCode: 'rosa_g07',        group: 7,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Erick Vásquez',                guestCode: 'erick_g07',       group: 7,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Adriana Yoselin',              guestCode: 'adriana_g08',     group: 8,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Rafael García',                guestCode: 'rafael_g08',      group: 8,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Elias Rafael',                 guestCode: 'elias_g08',       group: 8,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Lhia Aimee',                   guestCode: 'lhia_g08',        group: 8,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Albairis Vásquez',             guestCode: 'albairis_g09',    group: 9,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Sarah Isabel',                 guestCode: 'sarah_g09',       group: 9,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Enmanuel Ramón',               guestCode: 'enmanuel_g09',    group: 9,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Abel Angeles',                 guestCode: 'abel_g10',        group: 10,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Marielis Esperanza',           guestCode: 'marielis_g10',    group: 10,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Arisleny Ayala',               guestCode: 'arisleny_g11',    group: 11,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Sebastian Diaz',               guestCode: 'sebastian_g11',   group: 11,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Arleska Monegro',              guestCode: 'arleska_g12',     group: 12,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Fernando Jorge',               guestCode: 'fernando_g12',    group: 12,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Leida Rosario',                guestCode: 'leida_g13',       group: 13,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Erwin Lopez',                  guestCode: 'erwin_g13',       group: 13,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Jean Pierre',                  guestCode: 'jean_g14',        group: 14,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Glenmari Peña',                guestCode: 'glenmari_g14',    group: 14,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Jean Marcos',                  guestCode: 'jean_g15',        group: 15,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },
  // { name: 'Catherine Cepeda',             guestCode: 'catherine_g15',   group: 15,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Cesar Alfau',                  guestCode: 'cesar_g16',       group: 16,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Brendalis Zabala',             guestCode: 'brendalis_g16',   group: 16,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Deyvis Delgado',               guestCode: 'deyvis_g17',      group: 17,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Rubi Peña',                    guestCode: 'rubi_g17',        group: 17,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Eduviges Robles',              guestCode: 'eduvigues_g18',   group: 18,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Luis Abreu',                   guestCode: 'luis_g18',        group: 18,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Elionel Alvarez',              guestCode: 'elionel_g19',     group: 19,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Erica Jose',                   guestCode: 'erica_g19',       group: 19,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Victoria Morillo',             guestCode: 'victoria_g20',    group: 20,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Eddy Santos',                  guestCode: 'eddy_g20',        group: 20,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Mabelin Santos',               guestCode: 'mabelin_g20',     group: 20,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Eddison Santos',               guestCode: 'eddison_g20',     group: 20,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Yolenny Monegro',              guestCode: 'yolenny_g20',     group: 20,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Emily Hernandez',              guestCode: 'emily_g21',       group: 21,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Michael Rodriguez',            guestCode: 'michael_g21',     group: 21,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Carily',                       guestCode: 'carily_g22',      group: 22,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Jhon Esposo de Carily ',       guestCode: 'jhon_g22',        group: 22,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Elsa Vargas',                  guestCode: 'elsa_g23',        group: 23,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'José Beato',                   guestCode: 'josé_g23',        group: 23,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Angelica Almanzar',            guestCode: 'angelica_g24',    group: 24,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Anthony Nuñez',                guestCode: 'anthony_g24',     group: 24,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Biviana Brito',                guestCode: 'biviana_g25',     group: 25,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Ramón Jacobo',                 guestCode: 'ramon_g25',       group: 25,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Albania Vásquez',              guestCode: 'albania_g26',     group: 26,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Edward Holguín',               guestCode: 'edward_g26',      group: 26,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Audrey ',                      guestCode: 'audrey_g27',      group: 27,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Lesley Baez',                  guestCode: 'lesley_g27',      group: 27,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Ricky Espinal',                guestCode: 'ricky_g27',       group: 27,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Carmen Grullón',               guestCode: 'carmen_g28',      group: 28,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Puro Cornelio',                guestCode: 'puro_g28',        group: 28,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Jonas Cornelio',               guestCode: 'jonas_g28',       group: 28,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Valentino Cornelio',           guestCode: 'valentino_g28',   group: 28,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Carolyn Conill',               guestCode: 'carolyn_g29',     group: 29,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Yuly Encarnación',             guestCode: 'yuly_g29',        group: 29,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Debora Coronado',              guestCode: 'debora_g30',      group: 30,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Esperanza Coronado',           guestCode: 'esperanza_g30',   group: 30,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Maireni Coronado',             guestCode: 'maireni_g30',     group: 30,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Endry Mora',                   guestCode: 'endry_g31',       group: 31,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },
  // { name: 'Luis Mercedez',                guestCode: 'luis_g31',        group: 31,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Esposo Maribel',               guestCode: 'esposo_g32',      group: 32,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Maribel Bello',                guestCode: 'maribel_g32',     group: 32,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Johanna Monción',              guestCode: 'johanna_g34',     group: 34,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },
  // { name: 'Robert Conill',                guestCode: 'robert_g34',      group: 34,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Griselda Bonilla',             guestCode: 'griselda_g35',    group: 35,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Nereida Bonilla',              guestCode: 'nereida_g35',     group: 35,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Kevin Peralta',                guestCode: 'kevin_g36',       group: 36,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Laura Rodriguez',              guestCode: 'laura_g36',       group: 36,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Yolanda Vargas',               guestCode: 'yolanda_g37',     group: 37,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Junior Ramirez',               guestCode: 'junior_g37',      group: 37,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Jonas Ramirez',                guestCode: 'jonas_g37',       group: 37,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Kimberly Ramirez',             guestCode: 'kimberly_g38',    group: 38,  goToParty: false,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Rocio Despradel',              guestCode: 'rocio_g39',       group: 39,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Lindita Despradel',            guestCode: 'lindita_g39',     group: 39,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Luisanna Rodríguez',           guestCode: 'luisanna_g40',    group: 40,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Yohaniel Polanco',             guestCode: 'yohaniel_g40',    group: 40,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Mama Silvia',                  guestCode: 'mama_g41',        group: 41,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Puro Cornelio (Esp Silvia)',   guestCode: 'puro_g41',        group: 41,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Alexander Ruiz',               guestCode: 'alexander_42',   group: 42,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Fabricio Gonell',              guestCode: 'fabricio_43',    group: 43,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Helmer Sosa',                  guestCode: 'helmer_44',      group: 44,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Ysabel Rojas',                 guestCode: 'ysabel_45',      group: 45,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Robert Moricete',              guestCode: 'robert_46',      group: 46,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Penelope Contreras',           guestCode: 'penelope_47',    group: 47,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Michel Durán',                 guestCode: 'michel_48',      group: 48,  goToParty: true,  message: '', showConfirm: false, confirmed: true, invitationSend: false },

  // { name: 'Alberto Santos',               guestCode: 'alberto_49',     group: 49,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Frarlin Mora',                 guestCode: 'frarlin_50',     group: 50,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Dalina Toribio',               guestCode: 'dalina_52',      group: 52,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Dolores Cordova',              guestCode: 'dolores_53',     group: 53,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Mercedes Ortega',              guestCode: 'mercedes_54',    group: 54,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Enmanuel Montaño',             guestCode: 'enmanuel_55',    group: 55,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Hilda ...',                    guestCode: 'hilda_56',       group: 56,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'John Anthony',                 guestCode: 'john_57',        group: 57,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'José Miguel García',           guestCode: 'josé_58',        group: 58,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Lenny Santos',                 guestCode: 'lenny_59',       group: 59,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Maria Valdez',                 guestCode: 'maria_60',       group: 60,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Mariana Santos',               guestCode: 'mariana_61',     group: 61,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Markis Rodriguez',             guestCode: 'markis_62',      group: 62,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Pamela Abreu',                 guestCode: 'pamela_63',      group: 63,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Rosa García',                  guestCode: 'rosa_64',        group: 64,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Saul Taveras',                 guestCode: 'saul_65',        group: 65,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Thailyn Mieses',               guestCode: 'thailyn_66',     group: 66,  goToParty: true,  message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Familia Vásquez',              guestCode: 'fam_vasquez',    group: 67,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },

  // { name: 'Encuentro de novios',          guestCode: 'ecn_77',         group: 68,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false }

  // { name: 'Muestra',                         guestCode: 'muestra',        group: 71,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: true },
  // { name: 'Nelfa Wright',                    guestCode: 'nelfa_70',       group: 70,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Austin Wright',                   guestCode: 'austin_70',      group: 70,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Gabriel Wright',                  guestCode: 'gabriel_70',     group: 70,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false }

  // { name: 'Marilyn Parra',                   guestCode: 'marilyn_72',     group: 72,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Belkis Santana',                  guestCode: 'belkis_73',      group: 73,  goToParty: false,  message: '', showConfirm: false, confirmed: false, invitationSend: false },
  // { name: 'Ana Cáceres',                     guestCode: 'ana_c74',        group: 74,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Ruth Duarte',                        guestCode: 'ruth_g75',        group: 75,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Nestor García',                      guestCode: 'nestor_g75',      group: 75,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Merian',                             guestCode: 'merian_g75',      group: 75,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Josue',                              guestCode: 'josue_g75',       group: 75,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Mariel Almonte',                     guestCode: 'mariel_g76',       group: 76,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Ramona Díaz',                        guestCode: 'ramona_g76',       group: 76,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Rafael Duran',                       guestCode: 'rafael_g76',       group: 76,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Genesis Hernández',                     guestCode: 'genesis_g77',      group: 77,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'María Hernández',                       guestCode: 'maria_g77',        group: 77,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Argentina Hernandez',                      guestCode: 'argentina_g77',    group: 77,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Joselin Ramírez',                        guestCode: 'joselin_g78',        group: 78,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Milagros Restituyo',                     guestCode: 'milagros_g78',       group: 78,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Pedro Ramirez',                          guestCode: 'pedro_g78',          group: 78,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Cristopher Ramos',                          guestCode: 'cristopher_g79',          group: 79,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },

  // { name: 'Margarita Jiménez',                   guestCode: 'margarita_g80',    group: 80,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'José Altagracia Vásquez',             guestCode: 'jose_g80',         group: 80,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Luisa Paulino',                       guestCode: 'luisa_g81',        group: 81,  goToParty: true,   message: '', showConfirm: true, confirmed: false, invitationSend: false },
  // { name: 'Cesar Jiminian', guestCode: 'Cesar_g82', group: 82, goToParty: true, message: '', showConfirm: true, confirmed: false, invitationSend: false },
  { name: 'itspartytime', guestCode: 'itspartytime', group: 200, goToParty: true, message: '', showConfirm: false, confirmed: false, invitationSend: true, isStaff: true },
];
