<section class="limiter mt-5 consideration-section">
  <div class="d-flex flex-column align-items-center gap-2 mb-1">
    <animated-icon [icon]="animatedIcon.information" ></animated-icon>
    <h1 class="mb-0 text-f-candle text-s-40 text-shadow">Consideraciones</h1>
  </div>
  <small class="text-center d-block w-100"> Para todos tener un dia inolvidable ten en cuenta estas consideraciones </small>

  <div class="stack-cards">
    <!-- Código de vestimenta -->
    <div class="custom-card">
      <div class="custom-container justify-content-around flex-row">
        <div class="d-flex flex-column justify-content-between w-100 h-100 py-3 ps-2 ps-md-3">
          <div class="d-flex flex-column">
            <span class="main-title">Código de vestimenta</span>
            <small class="sub-main-title">Formal</small>
          </div>

          <div class="d-flex flex-column align-items-start">
            <small class="mb-2"> Evita estos colores</small>
            <div class="d-flex align-items-center gap-2 justify-content-center">
              @for (color of colores; track $index) {
              <div class="colores"></div>
              }
            </div>
          </div>
        </div>

        <div class="position-absolute vestimentas-img">
          <img src="assets/img/vestimenta-f.png" alt="vestimenta femenina" class="img-fluid vestimenta-img femenino" />
          <img src="assets/img/vestimenta-m.png" alt="vestimenta masculina" class="img-fluid vestimenta-img masculino" />
        </div>
      </div>
    </div>

    <!-- Puntualidad -->
    <div class="custom-card">
      <div class="custom-container">
        <div class="d-flex flex-column justify-content-between w-100 h-100 py-3 ps-2 ps-md-3">
          <div class="d-flex flex-column">
            <span class="main-title">Puntualidad</span>
            <small class="sub-main-title">La ceremonia es el momento más especial y significativo para nosotros, nos encantaría que todos estén presentes desde el inicio y sobre todo queremos compartir cada instante con ustedes. ¡Esperamos verlos allí a tiempo!</small>
          </div>
          <div class="w-100 d-flex justify-content-center">
            <img src="assets/img/reloj.jpg" alt="reloj" class="img-fluid reloj-img" />
          </div>
        </div>
      </div>
    </div>

    <!-- NO NIÑOS -->
    <div class="custom-card">
      <div class="custom-container">
        <div class="d-flex flex-column justify-content-between w-100 h-100 py-3 ps-2 ps-md-3">
          <div class="d-flex flex-column">
            <span class="main-title">Niños felices en casa</span>
            <small class="sub-main-title">Nuestro deseo es que este día lo disfrutes al máximo, por lo que te invitamos a celebrar con nosotros en un ambiente solo para adultos. ¡Será una oportunidad perfecta para relajarte y divertirte!</small>
          </div>
          <div class="w-100 d-flex justify-content-center justify-content-md-start">
            <img src="assets/img/children.png" alt="niño" class="img-fluid children-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="custom-card">
      <div class="custom-container">
        <div class="d-flex flex-column justify-content-between w-100 h-100 py-3 ps-2 ps-md-3">
          <div class="d-flex flex-column">
            <span class="main-title">¡Captura el Amor!</span>
            <small class="sub-main-title">Ayúdanos a capturar cada momento! Sube tus fotos al álbum compartido y celebremos juntos.</small>
          </div>
          <div class="w-100 h-100 d-flex flex-column justify-content-around align-items-center">
            <img src="assets/img/camara.png" alt="camara" class="img-fluid camara-img" />
            
            <button type="button" class="btn btn-primary w-fit-content" (click)="redirectToGooglePhotos()">
              <div class="d-flex gap-2 flex-nowrap">
                Ir al álbum compartido
                <img src="assets/img/google_photos.png" alt="google_photos" class="img-fluid google-photos" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
