import { Timestamp } from '@angular/fire/firestore';

export class fun {
  /**
   * Convierte un timestamp a un objeto tipo Date.
   * @param timestamp - objeto timestamp.
   * @returns El nuevo objeto Date.
   * * @example fun.parseTimeStampToDate(...)
   */
  static parseTimeStampToDate(timestamp: Timestamp) {
    const millisecondsFromSeconds = timestamp.seconds * 1000;
    const millisecondsFromNanoseconds = timestamp.nanoseconds / 1000000;

    const date = new Date(millisecondsFromSeconds + millisecondsFromNanoseconds);
    return date;
  }

  /**
   * Esta función duplica el valor del parametro pasado retirandole la referencia al objeto principal.
   * @param obj - objeto a clonar.
   * @returns objeto duplicado son la referencia.
   * * @example fun.deepCloning({ name:'victor' }) => { name:'victor' }
   */

  static deepCloning(obj: object | Array<object>) {
    if (obj == null || typeof obj !== 'object') return obj;
    const copy = Array.isArray(obj) ? [...obj] : Object.create(Object.getPrototypeOf(obj));
    Object.getOwnPropertyNames(obj).forEach((key) => {
      const descriptor = Object.getOwnPropertyDescriptor(obj, key);

      if (descriptor && descriptor.value && descriptor.value instanceof Date) {
        descriptor.value = new Date(descriptor.value);
      } else if (descriptor && descriptor.value instanceof File) {
        descriptor.value = new File([descriptor.value], descriptor.value.name, {
          type: descriptor.value.type,
          lastModified: descriptor.value.lastModified,
        });
      } else if (descriptor && descriptor.value && typeof descriptor.value === 'object') {
        descriptor.value = fun.deepCloning(descriptor.value);
      }

      if (descriptor) Object.defineProperty(copy, key, descriptor);
    });
    return copy;
  }

  /**
   * Esta función valida que si una variable esta vacía.
   * @param value - objeto a evaluar.
   * @returns booleano identificando si esta vacío.
   * * @example fun.isEmpty('something') => false
   */

  static isEmpty(value: any) {
    return (
      // es null o indefinido
      value === null ||
      value === undefined ||
      // es una fecha invalida
      (value instanceof Date && isNaN(value.getTime())) ||
      // si tiene longitud 0
      (value.hasOwnProperty('length') && value.length == 0) ||
      // si es un string vacio o con lleno de espacios
      (typeof value == 'string' && value.trim().length == 0) ||
      // si es un objeto y no tiene propiedades
      (value.constructor == Object && Object.keys(value).length == 0)
    );
  }

  /**
   * Esta función valida que si una variable tiene valor
   * @param value - objeto a evaluar.
   * @returns booleano identificando si tiene valor.
   * * @example fun.isEmpty('something') => true
   */

  static hasValue(value: any) {
    return !fun.isEmpty(value);
  }

  /**
   * Esta función retorna un arreglo con los keys de un objeto
   * @param obj - objeto a evaluar.
   * @returns arreglo con los keys del objeto.
   * * @example fun.objectKeys({name:'victor'}) => ['name']
   */

  static objectKeys(obj: any) {
    return Object.keys(obj);
  }

  /**
   * Esta funcion detiene la ejecucion de un metodo asincrono por un tiempo determinado
   * @param time - el tiempo a esperar.
   * * @example fun.sleep(1000)
   */
  static sleep(time: number = 0) {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), time);
    });
  }

  /**
   * Esta funcion hace scroll hasta el elemento seleccionado
   */
  static scrollTo(params: { id: string; block: 'start' | 'center' | 'end' | 'nearest'; inline: 'start' | 'center' | 'end' | 'nearest'; behavior: 'auto' | 'smooth' }) {
    const element = document.getElementById(`${params.id}`);

    if (params.id && element) element.scrollIntoView({ behavior: params.behavior, block: params.block, inline: params.inline });
  }

  /**
   * Esta funcion retorna el path actual de la url
   */
  static getActualPath() {
    const actualPath = window.location.pathname;
    const parameters = window.location.search;
    return `${actualPath}${fun.hasValue(parameters) ? parameters : ''}`;
  }

  /**
   * Esta funcion añada o quita una clase de un elemento html
   */
  static ModificarClass(selector: string, clase: string | string[], action: 'add' | 'remove') {
    if (!Array.isArray(clase)) clase = [clase];

    const htmlElement = document.querySelector(selector);

    if (htmlElement) {
      clase.forEach((c) => htmlElement.classList[action](c));
    }
  }
}
