import { CommonModule } from '@angular/common';
import { GuestClass } from '../../../core/clases/guest.class';
import { ActivityService } from './../../../core/services/activity.service';
import { Component, inject, ViewChild } from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import { fun } from '../../../core/services/functions.service';
import { SortEvent } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LocalstorageService } from '../../../core/services/localstorage.service';
import { LSAdminSignInTime } from '../../../core/models/date.model';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../../../core/typescript/date.utils';
import { FormInputDirective } from '../../../core/directives/form-input.directive';
import { Router } from '@angular/router';
import { routeE } from '../../../core/models/router.model';
import { guestList } from '../../../core/typescript/guestList';
import { ToastService, toastType } from '../../../core/services/toast.service';
import { ActivityClass } from '../../../core/clases/activity.class';

@Component({
  selector: 'app-assistance-list',
  standalone: true,
  imports: [TableModule, CommonModule, FormsModule, SelectButtonModule, FormInputDirective],
  templateUrl: './assistance-list.component.html',
  styleUrl: './assistance-list.component.scss',
})
export class AssistanceListComponent {
  @ViewChild('GuestListTable') guestListTable!: Table;
  router: Router = inject(Router);
  activityServ: ActivityService = inject(ActivityService);
  LocalstorageServ: LocalstorageService = inject(LocalstorageService);
  toastServ: ToastService = inject(ToastService);

  loading = true;
  guestList: GuestClass[] = [];
  initialValue: GuestClass[] = [];
  isSorted: any = null;
  showPassword: boolean = false;
  filterWord: any = '';
  fun = fun;

  selectButtonOptions = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ];
  currentFilterPresent!: any;

  selectedGuest!: GuestClass | undefined;
  sessionActive: boolean = false;
  adminCode = '';

  ngOnInit(): void {
    try {
      this.initializeTableData(this.activityServ.allGuest() ?? []);

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
    this.verifySession();
  }

  initializeTableData(guestList: GuestClass[]) {
    this.guestList = guestList.filter((guest) => (!('isStaff' in guest) || !guest.isStaff) && guest.goToParty);

    this.initialValue = fun.deepCloning(this.guestList);
  }

  verifySession() {
    const signInTime = this.LocalstorageServ.getItem(LSAdminSignInTime) ?? null;

    if (!isNaN(Number(signInTime)) && DateUtils.isValid(new Date(Number(signInTime)))) {
      const actualTime = new Date().getTime();
      const maxTimeAvailable = DateUtils.AddHours(1, new Date(Number(signInTime))).getTime();

      this.sessionActive = fun.hasValue(signInTime) && actualTime < maxTimeAvailable;
    } else {
      this.deleteSession();
    }
  }

  saveSession() {
    if (this.adminCode == environment.listPsw) {
      const fechaActual = new Date().getTime();
      this.LocalstorageServ.setItem(LSAdminSignInTime, `${fechaActual}`);
      this.sessionActive = true;
      this.adminCode = '';
    } else {
      const inputGuestCode = document.querySelector('#input-admin-code');
      const guestCodeMsg = document.querySelector('#msgContainer');

      inputGuestCode?.classList.add('invalid');

      if (guestCodeMsg) {
        if (fun.hasValue(this.adminCode)) {
          guestCodeMsg.textContent = 'El Código introducido es invalido';
        } else {
          guestCodeMsg.textContent = 'Debe de introducir el código';
        }
      }
      this.deleteSession();
    }
  }

  deleteSession() {
    this.LocalstorageServ.removeItem(LSAdminSignInTime);
    this.sessionActive = false;
  }

  navigateBack() {
    this.router.navigateByUrl(routeE.root);
  }

  customSort(event: SortEvent) {
    if (this.isSorted == null || this.isSorted === undefined) {
      this.isSorted = true;
      this.sortTableData(event);
    } else if (this.isSorted == true) {
      this.isSorted = false;
      this.sortTableData(event);
    } else if (this.isSorted == false) {
      this.isSorted = null;
      this.guestList = fun.deepCloning(this.initialValue);
      this.guestListTable.reset();
    }
  }

  sortTableData(event: any) {
    event.data.sort((data1: any, data2: any) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result: number = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  customFilter(type: 'present') {
    const selectedOpt = { present: { opt: this.currentFilterPresent, key: 'present' } };

    if (fun.hasValue(selectedOpt[type].opt)) {
      this.guestListTable.filter(selectedOpt[type].opt, selectedOpt[type].key, 'equals');
    } else {
      this.guestListTable.filter('', selectedOpt[type].key, 'equals');
      delete this.guestListTable.filters[selectedOpt[type].key];
    }
  }

  async updateGuestProp(prop: 'present', guestToUpdate: GuestClass) {
    try {
      guestToUpdate[prop] = !guestToUpdate[prop];

      await guestToUpdate.update();

      const guestUpdated = this.activityServ.allGuest()?.find((guest) => guest.id == guestToUpdate.id);

      if (guestUpdated) guestUpdated[prop] = guestToUpdate[prop];

      const msg = {
        success: {
          present: `Asistencia de: ${guestToUpdate.name}, confirmada.`,
        },
        danger: {
          present: `Asistencia de: ${guestToUpdate.name}, cancelada.`,
        },
      };

      if (guestToUpdate[prop]) this.toastServ.show(toastType.success, msg.success[prop]);
      else this.toastServ.show(toastType.error, msg.danger[prop]);
    } catch (error) {
      guestToUpdate[prop] = !guestToUpdate[prop];
    }
  }

  async createGuests() {
    for (const guest of guestList) {
      const guestClass = new GuestClass(environment.activity_id, guest as any);
      await guestClass.create();
    }
  }

  async reloadGuestList() {
    const currentActivity = this.activityServ.currentActivity();

    if (currentActivity) {
      const currentActivityClass = new ActivityClass(currentActivity);
      const allGuest = (await currentActivityClass.getAllGuests({ parsedToClass: true })) as GuestClass[];

      if (allGuest) this.activityServ.allGuest.set(allGuest);

      this.initializeTableData(allGuest ?? []);
    }
  }
}
